import React from "react";
import { FetchWithToken, authContext } from "../adal";
import Cookies from "js-cookie";
import { Breadcrumb } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { withRouter } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionsCount: 0,
      visibility: "none",
      breadcrumbs: [],
    };
  }
  componentDidMount() {
    var breadcrumbs = [];
    this.props.breadcrumbs.forEach((breadcrumb, index) => {
      breadcrumbs.push(
        <Breadcrumb.Item
          key={Math.random()}
          active={index === this.props.breadcrumbs.length - 1}
          onClick={() => {
            this.props.history.push(breadcrumb.link);
          }}
        >
          {breadcrumb.title}
        </Breadcrumb.Item>
      );
    });
    this.setState({
      breadcrumbs: breadcrumbs,
    });
    var admin = false;
    var cookies = Cookies.get("roles");
    var roles = cookies === undefined ? ["user"] : JSON.parse(cookies);
    for (const role of roles) {
      if (role === "admin") {
        admin = true;
      }
    }
    if (admin && !this.props.hideControls) {
      this.setState({ visibility: "block" });
    }
    FetchWithToken("count/submission", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          this.setState({
            submissionsCount: response,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  render() {
    return (
      <div className="header-menu">
        <Breadcrumb style={{ float: "left", padding: 0 }}>
          {this.state.breadcrumbs}
        </Breadcrumb>

        <a
          style={{
            float: "right",
            cursor: "pointer",
            fontSize: "1em",
            marginLeft: "1em",
            marginRight: "10px",
          }}
          onClick={() => {
            Cookies.remove("roles");
            authContext.logOut();
          }}
        >
          <i className="fa fa-sign-out"></i>
        </a>
        <a
          style={{ display: this.state.visibility, marginRight: "10px" }}
          className="delete"
          onClick={() => this.props.onClose()}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </a>
        <a
          style={{ display: this.state.visibility, marginRight: "10px" }}
          className="view"
          onClick={() =>
            (window.location.href = this.props.link + this.props.id)
          }
        >
          <i className={this.props.editIcon} aria-hidden="true"></i>
        </a>
        <div className="counter-container">
          <a
            style={{ color: "#0B5EC1" }}
            onClick={() => {
              this.props.history.push("/submissions");
            }}
          >
            Submissions
          </a>
          <span className="counter">{this.state.submissionsCount}</span>
        </div>
        {/* <a style={{float: "right"}} href="/">Home</a> */}
      </div>
    );
  }
}

export default withRouter(Header);
