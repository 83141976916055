import { AuthenticationContext, adalGetToken } from "react-adal";

export const API_URL = process.env.HOST_URL
  ? `${process.env.HOST_URL}/api`
  : `${window.location.origin}/api`;

const adalConfig = {
  tenant: "95924808-3044-4177-9c1b-713746ffab95",
  clientId: "9f5cc18f-5422-4224-917f-26bb7034dff1",
  redirectUri: window.location.origin,
  endpoints: {
    api: "9f5cc18f-5422-4224-917f-26bb7034dff1",
  },
  apiUrl: API_URL,
  cacheLocation: "localStorage",
};
export const authContext = new AuthenticationContext(adalConfig);

// authContext.acquireToken(adalConfig.clientId, function (errDesc, token, err, tokenType) {
//   if (token) {
//       // Make sure ADAL gave us an id token
//       if (tokenType !== authContext.CONSTANTS.ID_TOKEN) {
//           token = authContext.getCachedToken(adalConfig.clientId);
//       }
//   } else {
//       console.log("Renewal failed: " + err);
//   }
// });

// export const getToken = () =>  authContext.getCachedToken(adalConfig.clientId)

export const getToken = () => {
  return adalGetToken(authContext, adalConfig.endpoints.api);
};

export const FetchWithToken = async (handler, req_method, payload) =>
  fetch(`${API_URL}/${handler}`, {
    method: req_method,
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  });

//   authContext.acquireToken(config.clientId, function (errDesc, token, err, tokenType) {
//     if (token) {
//         // Make sure ADAL gave us an id token
//         if (tokenType !== authContext.CONSTANTS.ID_TOKEN) {
//             token = authContext.getCachedToken(config.clientId);
//         }
//         showProfileInformation(idToken);
//     } else {
//         console.log("Renewal failed: " + err);
//         // Failed to get the token silently; show the login button
//         showLoginButton();
//         // You could attempt to launch the login popup here, but in browsers this could be blocked by
//         // a popup blocker, in which case the login attempt will fail with the reason FailedToOpenWindow.
//     }
// });
