import React from "react";
import Header from "../../header";
import { FetchWithToken, API_URL, authContext, getToken } from "../../../adal";
import dompurify from "dompurify";
import { Loader, TagPicker } from "rsuite";
import Cookies from "js-cookie";

export class SubmissionViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.formatDate = this.formatDate.bind(this);
    this.renderField = this.renderField.bind(this);
    this.WrikeStatus = this.WrikeStatus.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.shareWith = this.shareWith.bind(this);
    this.resubmit = this.resubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.getUsers();
    FetchWithToken("submission/" + this.props.match.params.id, "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var json = response.data;
          this.setState({
            submission: json,
          });
          FetchWithToken("form/" + json.form, "GET")
            .then((res) => res.json())
            .then(
              (response) => {
                this.setState({
                  form: response.data,
                });
              },
              (error) => {
                console.error(error);
              }
            );
          if (json.wrikeProjectId !== undefined) {
            FetchWithToken("wrike/status/" + json.wrikeProjectId, "GET")
              .then((res) => res.json())
              .then(
                (response) => {
                  if (response.error === "project not found") {
                    this.setState({
                      status: { color: "#FF0000", name: "Project Not Found" },
                    });
                  } else {
                    this.setState({
                      status: response.data,
                    });
                  }
                },
                (error) => {
                  console.error(error);
                }
              );
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  reset() {
    var submission = JSON.parse(this.state.submission.raw);
    FetchWithToken("wrike/reset", "POST", submission)
      .then((res) => res.json())
      .then((response) => {
        let projectLink = response.data.wrikeLink;
        if (projectLink !== "")
          window.open(this.state.submission.wrikeLink, "_blank");
      });
  }

  resubmit() {
    var submission = JSON.parse(this.state.submission.raw);
    submission.id = "";
    submission.wrikeLink = "";
    submission.wrikeProjectId = "";
    // return
    if (
      this.state.form.blueprint !== undefined ||
      this.state.form.task !== undefined
    ) {
      // wrike/submit triggers form/submit
      FetchWithToken("wrike/submit", "POST", submission)
        .then((res) => res.json())
        .then((response) => {
          let projectLink = response.data.wrikeLink;
          if (projectLink !== "") window.open(projectLink, "_blank");
        });
    } else {
      FetchWithToken("form/submit", "POST", submission);
    }

    if (this.state.form.submit !== undefined) {
      fetch(this.state.form.submit, {
        method: "POST",
        headers: {
          Authorization: `${getToken()}`,
          Accept: "application/json",
        },
        body: JSON.stringify(submission),
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  shareWith(users) {
    if (users === null) {
      users = [];
    }
    var temp = this.state.submission;
    temp.shared = users;

    FetchWithToken("submission/share", "POST", temp);
    this.setState({ temp });
  }

  getUsers() {
    FetchWithToken("user", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var users = [];
          response.data.map((user) =>
            users.push({ label: user.name, value: user.email })
          );
          this.setState({
            users: users,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }
  formatDate(date, showTime) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let hour = date.getHours().toString().padStart(2, "0");
    let minute = date.getMinutes().toString().padStart(2, "0");
    if (showTime) {
      return `${day}.${month}.${year} ${hour}:${minute}`;
    }
    return `${day}.${month}.${year}`;
  }

  renderField(field) {
    if (
      this.state.form === undefined ||
      this.state.form.components === null ||
      this.state.form.components === undefined
    ) {
      return (
        <li key={field.Key}>
          <b>{field.Key}</b>: {`${field.Value}`}
        </li>
      );
    }
    return this.state.form.components.map((element) => {
      if (
        element.key === field.Key &&
        element.type !== "button" &&
        field.Value !== ""
      ) {
        var value =
          element.type === "datetime"
            ? this.formatDate(new Date(field.Value), false)
            : field.Value + "";
        if (element.type === "select" && field.Value !== null) {
          if (typeof field.Value === "object") {
            var values = [];
            element.data.values.forEach((el) => {
              field.Value.forEach((val) => {
                if (el.value == val) {
                  values.push(el.label);
                }
              });
            });
            if (values.length < 1) {
              return "";
            }
            value = values.join(", ");
          }
          element.data.values.forEach((el) => {
            if (el.value == field.Value) {
              value = el.label;
            }
          });
        }
        if (element.type === "selectboxes" && field.Value !== null) {
          var values = [];
          field.Value.forEach((f) => {
            element.values.forEach((el) => {
              if (el.value == f.Key && f.Value) {
                values.push(el.label);
              }
            });
          });
          if (values.length < 1) {
            return "";
          }
          value = values.join(", ");
        }
        if (element.type === "file" && field.Value !== null) {
          var files = [];
          field.Value.forEach((file) => {
            var fileName = "";
            var fileID = "";
            file.forEach((field) => {
              if (field.Key === "id") {
                fileID = field.Value;
              }
              if (field.Key === "originalName") {
                fileName = field.Value;
              }
            });
            files.push({ fileName: fileName, id: fileID });
          });
          return (
            <li key={field.Key}>
              <b>{element.label}</b> ({element.type}):{" "}
              {files.map((file) => {
                return (
                  <div>
                    <a
                      download={file.fileName}
                      href={`${API_URL}/file/${file.id}`}
                    >
                      {file.fileName}
                    </a>
                    <br />
                  </div>
                );
              })}
            </li>
          );
        }
        if (element.type === "excelTable" && field.Value !== null) {
          var rows = JSON.parse(field.Value);

          return (
            <li key={field.Key}>
              <b>{element.label}</b>:<br />
              <table className="excelTable" key={field.Key}>
                <tbody>
                  {rows.map((row, index) => {
                    return (
                      <tr key={Math.random()}>
                        {row.map((column) => {
                          return (
                            <td
                              key={Math.random()}
                              style={{
                                fontWeight: index === 0 ? "bold" : "normal",
                              }}
                            >
                              {column}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </li>
          );
        }
        return (
          <li key={field.Key}>
            <b>{element.label}</b> ({element.type}): {value}
          </li>
        );
      }
    });
  }

  WrikeStatus() {
    if (this.state.submission.wrikeProjectId === undefined) {
      return "";
    }
    if (this.state.status === undefined) {
      return (
        <div>
          <h4>Wrike Project Info</h4>
          <ul>
            <li>
              <b>Title</b>:{" "}
              <a target="_blank" href={this.state.submission.wrikeLink}>
                {this.state.submission.title}
              </a>
            </li>
            {/* <li><b>ID</b>: <Loader /></li> */}
            <li>
              <b>Status</b>: <Loader />
            </li>
          </ul>
          <br />
        </div>
      );
    } else {
      return (
        <div>
          <h4>Wrike Project Info</h4>
          <ul>
            <li>
              <b>Title</b>:{" "}
              <a target="_blank" href={this.state.submission.wrikeLink}>
                {this.state.submission.title}
              </a>
            </li>
            {/* <li><b>ID</b>: {this.state.status.id}</li> */}
            <li>
              <b>Status</b>:
              <span style={{ color: this.state.status.color }}>
                {" "}
                {this.state.status.name}
              </span>
            </li>
          </ul>
          <button
            className="save"
            type="submit"
            onClick={() => {
              this.reset();
            }}
          >
            Reset Task
          </button>
          <br />
          <br />
          <br />
        </div>
      );
    }
  }

  render() {
    if (this.state.submission === undefined) {
      return (
        <div className="container links-container">
          <h2>Loading submission...</h2>
        </div>
      );
    } else {
      return (
        <div>
          <Header
            breadcrumbs={[
              { title: "Home", link: "/" },
              { title: "Submissions", link: "/submissions" },
              { title: this.state.submission.title || "New", link: "" },
            ]}
            back={this.props.history.goBack}
            hideControls={true}
          ></Header>
          <div style={{ overflow: "scroll" }} className="container">
            <h4>Form Info</h4>
            {this.state.form === undefined ? (
              <Loader />
            ) : (
              <ul>
                <li>
                  <b>Title</b>:{" "}
                  <a
                    onClick={() => {
                      this.props.history.push(
                        "/form/view/" + this.state.form.id
                      );
                    }}
                  >
                    {this.state.form.title}
                  </a>
                </li>
                <li>
                  <b>Description</b>:{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.form.description !== undefined
                          ? dompurify.sanitize(this.state.form.description)
                          : "description is not provided.",
                    }}
                  ></div>
                </li>
                <li>
                  <b>Author</b>:{" "}
                  {this.state.form.author ? this.state.form.author : "unknown"}
                </li>
                <li>
                  <b>Created At</b>:{" "}
                  {this.formatDate(new Date(this.state.form.created), true)}
                </li>
              </ul>
            )}

            <br />
            <this.WrikeStatus></this.WrikeStatus>
            <h4>Submission Info</h4>
            {this.state.submission === undefined ? (
              <Loader />
            ) : (
              <div>
                <ul>
                  <li>
                    <b>Title</b>: {this.state.submission.title}
                  </li>
                  <li>
                    <b>Author</b>: {this.state.submission.author}
                  </li>
                  <li>
                    <b>Created At</b>:{" "}
                    {this.formatDate(
                      new Date(this.state.submission.created),
                      true
                    )}
                  </li>
                </ul>
                {authContext._user.profile.email ===
                  this.state.submission.email ||
                JSON.parse(Cookies.get("roles")).includes("admin") ? (
                  <TagPicker
                    creatable
                    placeholder="Shared with..."
                    value={this.state.submission.shared}
                    onChange={this.shareWith}
                    block
                    data={this.state.users}
                    menuStyle={{ width: 300 }}
                  />
                ) : (
                  ""
                )}
                <br />
                <h4>Submission Data</h4>
                <ul>
                  {this.state.submission.data === null
                    ? "No data provided"
                    : this.state.submission.data === undefined
                    ? ""
                    : this.state.submission.data.map((element) =>
                        this.renderField(element)
                      )}
                </ul>
              </div>
            )}
            <br />
            <br />
            <button
              className="save"
              type="submit"
              onClick={() => {
                this.resubmit();
              }}
            >
              Resubmit
            </button>
          </div>
        </div>
      );
    }
  }
}
export default SubmissionViewer;
