import React from "react";
import Header from "../../header";
import Delete from "../../deleteModal";
import 'react-toastify/dist/ReactToastify.css';
import { TagPicker, Alert } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import {FetchWithToken} from "../../../adal";

export class folderEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showModal: false,
      folder: {},
      allTags: [],
      tags: [],
      source: {},
      create: false
    };
    this.SubmitFolder = this.SubmitFolder.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.CancelEditing = this.CancelEditing.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      FetchWithToken("folder/" + this.props.match.params.id, "GET")
        .then((res) => res.json())
        .then(
          (response) => {
            var json = response.data;
            console.log(json)

            this.setState({
              folder: JSON.parse(JSON.stringify(json)),
              tags: json.tags,
              source: JSON.parse(JSON.stringify(json)),
              ready: true
            });
          },
          (error) => {
            console.error(error);
          }
        ).catch((error) => {
          console.error('Error:', error);
        });
      } else {
        this.setState({
            folder: {parent: this.props.location.state ? this.props.location.state.parent ? this.props.location.state.parent : "" : ""},
            create: true,
            ready: true
          });
      }
      FetchWithToken("tags", "GET")
        .then((res) => res.json())
        .then(
          (response) => {
            var tags = []
            response.data.map((element) => tags.push({label: element, value: element}))
            this.state.tags.forEach(element => {
                if (!response.data.includes(element)) {
                    tags.push({label: element, value: element})
                }
            })
            this.setState({
              allTags: tags
            });
          },
          (error) => {
            console.error(error);
          }
        )
  }


  SubmitFolder() {
    var folder = this.state.folder;
    folder.tags = this.state.tags;
    FetchWithToken("folder", "POST",  folder).then((res) => res.json()).then(
      (response) => {
      if (this.state.create) {
        this.props.history.goBack();
      }
      Alert.success('Folder has been successfully saved')
    }).catch((error) => {
      console.log(error)
      Alert.error('Something went wrong')
    })
  }
  CancelEditing() {
    this.setState({folder: this.state.source });
  }
  onClose() {
    this.setState({showModal: !this.state.showModal})
  }
  handleChange(tagValue) {
    this.setState({ tags: tagValue });
  }


  render() {
      
    if (!this.state.ready) {
      return (<div className="container" >Loading...</div>)
    } else {
      return (
        <div>
          <Delete redirect="/folders" onClose={this.onClose} showModal={this.state.showModal} instanceName="folder" instance={this.state.folder}></Delete>
          <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Folders", link: "/folders"}, {title: this.state.folder.title || "New", link: ""}]} hideControls={this.state.create} back={this.props.history.goBack} onClose={this.onClose} link={"/folder/"} editIcon={"fa fa-print"} id={this.state.folder.id}></Header>
      <div className="container" > 
      <label className="col-form-label field-required">Parent Folder</label>
        <input value={this.state.folder.parent || ''} type="text" onChange={(event) => {
            var source = this.state.folder;
            source.parent = event.target.value;
            this.setState({folder: source})
        }}></input>
        <label className="col-form-label field-required">Title</label>
        <input value={this.state.folder.title || ''} type="text" onChange={(event) => {
            var source = this.state.folder;
            source.title = event.target.value;
            this.setState({folder: source})
        }}></input>
        <label className="col-form-label field-required">Description (HTML Elements)</label>
        <textarea value={this.state.folder.description || ''} type="text" onChange={(event) => {
            var source = this.state.folder;
            source.description = event.target.value;
            this.setState({folder: source})
        }}></textarea>
        <br/>
        <br/>
        <label className="col-form-label field-required">Tags</label>
        <TagPicker value={this.state.tags} onChange={this.handleChange} style={{width: "100%", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px"}} creatable  data={this.state.allTags} defaultValue={this.state.folder.tags} menuStyle={{width: 300}} />
        <div style={{height:100}}></div>
        <button className="save" type="submit" onClick={this.SubmitFolder}>Save</button>
        <button style={{marginRight: "10px"}} className="cancel" type="submit" onClick={this.CancelEditing}>Cancel</button>
      </div>
      </div>
    );

    }
  }
}
export default folderEditor;
