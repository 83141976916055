import React from "react";



export class NotFound extends React.Component {
  render() {
    return (
        <div className="err-page">
            404
            <br/>
            Page Not Found
        </div>
      );
    }
}
export default NotFound;
