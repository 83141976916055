import React from "react";
import Link from "./link";
import { Pagination, Loader, SelectPicker, Toggle, DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import Header from "../../header";
import {FetchWithToken} from "../../../adal";
import Cookies from "js-cookie";



export class SubmissionExplorer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            showModal: false,
            page: 0,
            filterStatus: "",
        };
        this.handlePageSelect = this.handlePageSelect.bind(this);
        this.filterStatus = this.filterStatus.bind(this);
        this.search = this.search.bind(this);
    }
    componentDidMount() {
      this.search({dateRange: this.state.range, offset: this.state.page*10, limit: 10});
    }
  
    filterStatus(filterStatus) {
      if (filterStatus === null) {
        filterStatus = "";
        this.search({dateRange: this.state.range, offset: this.state.page*10, limit: 10, status: filterStatus});
      } else {
        this.search({status: filterStatus, dateRange: this.state.range, offset: 0, limit: 0});
      }
      this.setState({ filterStatus });
    }
  
    search(query) {
      this.setState({ready: false});
      FetchWithToken("submission", "POST", query)
      .then((res) => res.json())
      .then(
        (response) => {
          var page = this.state.page;
          var lastPage = Math.ceil(response.data.count/10)-1;
          if (page > lastPage) {
            this.setState({page: lastPage}, this.search({dateRange: this.state.range, offset: lastPage*10, limit: 10}));
          }
          else {
            var filterStatuses = [];
            if (response.data.filterStatuses !== null) {
              response.data.filterStatuses.map((status) => filterStatuses.push({label: status.name, value: status.name, color: status.color}));
            }
            this.setState({
              submissions: response.data.submissions,
              filterStatuses: filterStatuses,
              statuses: response.data.statuses,
              totalCount: response.data.count,
              ready: true
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
    onClose() {
      this.setState({showModal: !this.state.showModal})
    }
  
    handlePageSelect(eventKey) {
      this.setState({ page: eventKey-1 });
      this.search({dateRange: this.state.range, offset: (eventKey-1)*10, limit: 10});
    }

    render() {
    return (
      <div>
      <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Submissions", link: "/submissions"}]} back={this.props.history.goBack} hideControls={true} ></Header>
      <div style={{paddingBottom: "6em"}} className="container links-container">
      <div><ul>
      <div className="searchFields" style={{marginBottom: "2em"}}>
      <DateRangePicker isoWeek={true} disabledDate={DateRangePicker.afterToday(true)} style={{width: "49%", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px", float: "left"}}
      value={this.state.dateRange}
      onChange={value => {
        if (value.length === 0) {
          this.setState({dateRange: [], range: []});
          this.search({users: this.state.filterValue, dateRange: [], offset: 0, limit: 10});
          return
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var range = [(new Date(value[0] - tzoffset)).toISOString(), (new Date(value[1] - tzoffset)).toISOString()]
        this.setState({ dateRange: value, range });
        this.search({users: this.state.filterValue, dateRange: range, offset: 0, limit: 0});
      }}
      />
      <SelectPicker cleanable renderMenuItem={(label, item) => {
      return (
        <div style={{color: item.color}}>
          <span>{label}</span>
        </div>
      );
    }} placeholder="Search by Wrike Status..." value={this.state.filterStatus} onChange={this.filterStatus} style={{width: "49%", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px", marginBottom:"1em", float: "right"}} data={this.state.filterStatuses} menuStyle={{width: 300}} />
      </div>
      <br/>
      <br/>
      <br/>

        <Toggle style={{float: "right"}} size="lg" checkedChildren="Table" unCheckedChildren="Default"
        onChange={(event) => {
            Cookies.set("table", "true");
            setTimeout(function(){ window.location.reload(); }, 1000);
        }}
         ></Toggle>
        <br/>
        <br/>
      {this.state.ready === false ? <div style={{textAlign: "center"}}><Loader size="md" /><br/><br/><br/></div> : this.state.submissions !== null ? this.state.submissions.map((s) => (
          <Link key={s.id}  submission={s} />
          )) : "Nothing found!"}
        </ul></div>
        <div style={{textAlign: "center"}}>
        {this.state.filterStatus !== "" ? "" : <Pagination prev next onSelect={this.handlePageSelect} pages={Math.ceil(this.state.totalCount/10)} activePage={this.state.page+1} />}
        </div>
      </div>
      </div>
    );
  }
}
export default SubmissionExplorer;
