import React from "react";
import Header from "../../header";
import Delete from "./deleteModal";
import {FetchWithToken} from "../../../adal";
import { TagPicker, Alert } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

export class userEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showModal: false,
      user: {},
      create: false
    };
    this.Submit = this.Submit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.email !== undefined) {
      FetchWithToken("user/" + this.props.match.params.email, "GET")
        .then((res) => res.json())
        .then(
          (response) => {
            var json = response.data;
            this.setState({
              roleValue: json.roles,
              user: JSON.parse(JSON.stringify(json)),
              ready: true
            });
          },
          (error) => {
            console.error(error);
          }
        ).catch((error) => {
          console.error('Error:', error);
        });
      } else {
        this.setState({
            create: true,
            roleValue: [],
            user: {name: "", email: "", roles: ["user"]},
            ready: true
          });
      }
      FetchWithToken("roles", "GET")
        .then((res) => res.json())
        .then(
          (response) => {
            var roles = []
            response.data.map((element) => {
              roles.push({label: element, value: element})
            })
            this.setState({
              roles: roles
            });
          },
          (error) => {
            console.error(error);
          }
        )
  }

  Submit() {
    var temp = this.state.user;
    temp.roles = (this.state.roleValue !== null ? this.state.roleValue : []);
    FetchWithToken("user", "POST",  temp).then((res) => res.json()).then(
      (response) => {
      if (this.state.create) {
      window.location.href = window.location.origin+"/user/edit/"+this.state.user.email
      }
      Alert.success('User has been successfully saved')
    }).catch((error) => {
      console.log(error)
      Alert.error('Failed to save user')
    })
  }
  onClose() {
    this.setState({showModal: !this.state.showModal})
  }
  handleRoleChange(roleValue) {
    this.setState({ roleValue });
  }


  render() {
      
    if (!this.state.ready) {
      return (<div className="container" >Loading...</div>)
    } else {
      return (
        <div>
          <Delete redirect="/users" onClose={this.onClose} showModal={this.state.showModal} instanceName="user" instance={this.state.user}></Delete>
          <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Users", link: "/users"}, {title: this.state.user.name || "New", link: ""}]} hideControls={this.state.create} back={this.props.history.goBack} onClose={this.onClose} link={"/user/"} editIcon={"fa fa-print"} id={this.state.user.id}></Header>
      <div className="container" > 
        <label className="col-form-label field-required">Name</label>
        <input value={this.state.user.name || ''} type="text" onChange={(event) => {
            var source = this.state.user;
            source.name = event.target.value;
            this.setState({user: source})
        }}></input>
        <label className="col-form-label field-required">Email</label>
        <input value={this.state.user.email || ''} type="text" onChange={(event) => {
            var source = this.state.user;
            source.email = event.target.value;
            this.setState({user: source})
        }}></input>
        <label className="col-form-label field-required">Roles</label>
        <TagPicker creatable value={this.state.roleValue} onChange={this.handleRoleChange} style={{width: "100%", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px"}}  data={this.state.roles} defaultValue={this.state.roleValue} menuStyle={{width: 300}} />
        <br/>
        <br/>
        <button className="save" type="submit" onClick={this.Submit}>Save</button>
      </div>
      </div>
    );

    }
  }
}
export default userEditor;
