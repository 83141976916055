import React from "react";
import Link from "./link";
import Header from "../../header";
import {FetchWithToken} from "../../../adal";


export class TemplateExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentDidMount() {
    FetchWithToken("template", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          this.setState({
            templates: response.data,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  render() {
    if (this.state.templates === undefined) {
      return (
        <div className="container links-container">
            <h2>Loading templates...</h2>
          </div>
      );
    } else {
      return (
        <div>
        <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Templates", link: "/templates"}]} back={this.props.history.goBack} hideControls={true}></Header>
        <div style={{paddingBottom: "6em"}} className="container links-container">
          
        <div><h2>Available Templates</h2>
            
            {this.state.templates !== null ? this.state.templates.map((t) => (
              <Link key={t.id}  template={t} />
            )) : "Nothing found!"}
          <button onClick={() => window.location.href = "/template/create"} className="save" style={{marginTop: "2em"}} type="submit" >Create New</button>
        </div>
        </div>
        </div>
      );
    }
  }
}
export default TemplateExplorer;
