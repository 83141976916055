import React from "react";
import Delete from "../../deleteModal";
import dompurify from "dompurify";
import { withRouter } from "react-router-dom";

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      ready: false,
    };
    this.formatDate = this.formatDate.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ showModal: !this.state.showModal });
  }

  formatDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}.${month}.${year}`;
  }
  render() {
    return (
      <div>
        <Delete
          onClose={this.onClose}
          redirect={window.location.pathname}
          showModal={this.state.showModal}
          instanceName={this.props.instanceName}
          instance={this.props.instance}
        ></Delete>
        <li className="link">
          <a
            className="delete"
            onClick={() => this.setState({ showModal: true })}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
          <a
            className="edit"
            onClick={() => {
              this.props.history.push(
                `/${this.props.instanceName}/edit/${this.props.instance.id}`
              );
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </a>
          <h5>
            {this.props.icon ? (
              <i
                className={this.props.icon}
                style={{ marginRight: 10 }}
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}

            {/* <i className="fa fa-folder" style={{marginRight:10}} aria-hidden="true"></i> */}
            {/* <i className="fa fa-file-text-o" style={{marginRight:10}} aria-hidden="true"></i> */}
            <a
              onClick={() => {
                this.props.history.push(
                  `/${this.props.instanceName}/view/${this.props.instance.id}`
                );
              }}
            >
              {this.props.instance.title} (
              {this.formatDate(new Date(this.props.instance.created))})
            </a>
          </h5>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html:
                this.props.instance.description !== undefined
                  ? dompurify.sanitize(this.props.instance.description)
                  : "Folder description is not provided.",
            }}
          ></div>
          <div className="description">
            {this.props.instance.tags !== undefined &&
            this.props.instance.tags !== null
              ? this.props.instance.tags.map((tag) => (
                  <button
                    onClick={() => console.log(tag)}
                    className="tag"
                    key={tag}
                  >
                    {tag}
                  </button>
                ))
              : ""}
          </div>
        </li>
      </div>
    );
  }
}

export default withRouter(Link);
