import React from "react";
import dompurify from "dompurify";
import { withRouter } from "react-router-dom";

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.getElementsByClassName("description").forEach((description) => {
      description.getElementsByTagName("a").forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    });
  }
  render() {
    return (
      <div>
        <li className="link" style={{ textAlign: "center" }}>
          <h5>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.history.push(this.props.href);
              }}
            >
              {this.props.title}
            </a>
          </h5>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html:
                this.props.description !== undefined
                  ? dompurify.sanitize(this.props.description)
                  : "",
            }}
          ></div>
        </li>
      </div>
    );
  }
}

export default withRouter(Link);
