import React from "react";
import Link from "./link";
import Header from "../../header";
import {FetchWithToken} from "../../../adal";


export class FolderViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentDidMount() {
    FetchWithToken("folder/"+this.props.match.params.id+"/view", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          this.setState({
            resources: response.data,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  render() {
    if (this.state.resources === undefined) {
      return (
        <div className="container links-container">
            <h2>Loading resources...</h2>
          </div>
      );
    } else {
      return (
        <div>
        <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Folders", link: "/folders"}]} back={this.props.history.goBack} hideControls={true} ></Header>
        <div style={{paddingBottom: "6em"}} className="container links-container">
            {this.state.resources !== null ? this.state.resources.map((r) => (
            <Link key={r.id} instanceName={r.type} icon={r.type === "form" ? "fa fa-file-text-o" : "fa fa-folder"} instance={r} />
            )) : "Nothing found!"}
          <button onClick={() => this.props.history.push('/folder/create', { parent: this.props.match.params.id })} className="save" style={{marginTop: "2em"}} type="submit" >Create New</button>
        </div>
        </div>
      );
    }
  }
}
export default FolderViewer;
