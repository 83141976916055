import React from "react";
import {FetchWithToken} from "../../adal";
import { Loader, Panel, Divider, Progress, Row, Col, Button, Timeline } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import Header from "../header";


export class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        response: {},
    };
    // this.addTag = this.addTag.bind(this);
  }
  

  componentDidMount() {
    FetchWithToken("service/info", "GET")
    .then((res) => res.json())
    .then(
      (response) => {
          this.setState({response: response})
      },
      (error) => {
        console.error(error);
      }
    )
    setInterval(() => {
        FetchWithToken("service/info", "GET")
        .then((res) => res.json())
        .then(
          (response) => {
            this.setState({response: response})
          },
          (error) => {
            console.error(error);
          }
        )
    }, 5000);
    
  }
  render() {
    return (
      <div>
      <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Info", link: "/info"}]} back={this.props.history.goBack} hideControls={true} ></Header>
      <div style={{paddingBottom: "6em"}} className="container links-container">
        <div>
            {/* <h2>Execution Tree</h2>
            <div>
                <Steps current={5}>
                    <Steps.Item title="Database" />
                    <Steps.Item title="Systemd Service" />
                    <Steps.Item title="API Server" />
                    <Steps.Item title="NGINX Static" />
                    <Steps.Item title="NGINX Proxy" />
                </Steps>
            </div> */}
        <div>
        {/* <Divider/> */}
        <div>
        <h2>Data</h2>
        <Row>
            <Col md={8} sm={16}>
                <Panel  bordered header="Forms">
                    <Progress.Circle percent={100} strokeColor="#f79164" status="success"  />
                </Panel>
            </Col>
            <Col md={8} sm={16}>
                <Panel  bordered header="Submissions">
                    <Progress.Circle percent={100} strokeColor="#f79164" status="success"  />
                </Panel>
            </Col>
            <Col md={8} sm={16}>
                <Panel  bordered header="Users">
                    <Progress.Circle percent={100} strokeColor="#f79164" status="success"  />
                </Panel>
            </Col>
        </Row>
        <Row>
            <Col md={8} sm={16}>
                <Panel style={{textAlign: "center"}} bordered>
                    {this.state.response.forms}
                </Panel>
            </Col>
            <Col md={8} sm={16}>
                <Panel style={{textAlign: "center"}} bordered>
                {this.state.response.submissions}
                </Panel>
            </Col>
            <Col md={8} sm={16}>
                <Panel style={{textAlign: "center"}} bordered>
                {this.state.response.users}
                </Panel>
            </Col>
        </Row>
        </div>
        <Divider/>
        <br/>
        <h2>Component Health</h2>
        <Row>
            <Col md={6} sm={12}>
                <Panel  bordered header="Database">
                    <Progress.Circle percent={100} strokeColor="#3385ff" status={null} />
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel  bordered header="Backend">
                    <Progress.Circle percent={100} strokeColor="#3385ff" status={null} />
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel  bordered header="Frontend">
                    <Progress.Circle percent={100} strokeColor="#3385ff" status={null} />
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel  bordered header="Wrike">
                    <Progress.Circle percent={100} strokeColor="#3385ff" status={null} />
                </Panel>
            </Col>
        </Row>
        </div>
        <div>
        <Divider/>
        <br/>
        <h2>Server</h2>
        <Row>
            <Col md={6} sm={12}>
                <Panel  bordered header="Disk">
                    <Progress.Circle percent={this.state.response.disk ? Math.round((100*this.state.response.disk.used)/this.state.response.disk.all) : 0} strokeColor="#f79164" status={null} />
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel  bordered header="CPU">
                    <Progress.Circle percent={this.state.response.cpu ? Math.round(this.state.response.cpu) : 0} strokeColor="#f79164" status={null} />
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel  bordered header="RAM">
                    <Progress.Circle percent={this.state.response.ram ? Math.round((100*this.state.response.ram.used)/this.state.response.ram.all) : 0} strokeColor="#f79164" status={null} />
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel  bordered header="Uptime">
                    <Progress.Circle percent={100} strokeColor="#f79164" status={null} />
                </Panel>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <Panel style={{textAlign: "center"}} bordered>
                {this.state.response.disk ? Math.round((this.state.response.disk.used)) : 0}GB
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel style={{textAlign: "center"}} bordered>
                {this.state.response.cpu ? Math.round(this.state.response.cpu) : 0}%
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel style={{textAlign: "center"}} bordered>
                {this.state.response.ram ? this.state.response.ram.used : 0}MB
                </Panel>
            </Col>
            <Col md={6} sm={12}>
                <Panel style={{textAlign: "center"}} bordered>
                {this.state.response.uptime ? this.state.response.uptime : "0 days, 0 hours, 0 minutes"}
                </Panel>
            </Col>
        </Row>
        </div>
        <br/>
        <br/>
        <div>
            <Button disabled block color="red">Reboot</Button>
        </div>
        </div>
        {/* <div><h2>Available Forms</h2> <ul>
          {this.state.ready === false ? <div style={{textAlign: "center"}}><Loader size="md" /><br/><br/><br/></div> :
          this.state.forms !== null ? this.state.forms.map((f) => (
            <Link highlight={this.highlight} addTag={this.addTag} key={f.id}  form={f} />
          )) : "Nothing found!"}
        </ul></div> */}
      </div>
      </div>
    );
  }
}
export default Info;
