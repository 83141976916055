import React from "react";
import { FetchWithToken } from "../../../adal";
import { Loader } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";

import { withRouter } from "react-router-dom";
// import Delete from "../deleteModal"

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.formatDate = this.formatDate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.hexToRgbA = this.hexToRgbA.bind(this);
  }

  onClose() {
    this.setState({ showModal: !this.state.showModal });
  }
  componentDidMount() {
    if (this.props.submission.wrikeProjectId !== undefined) {
      FetchWithToken(
        `wrike/status/${this.props.submission.wrikeProjectId}`,
        "GET"
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.error === "project not found") {
            this.setState({
              wrikeStatus: { color: "#FF0000", name: "Project Not Found" },
            });
            return;
          }
          this.setState({
            wrikeStatus: response.data,
          });
        });
    }
  }
  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",20%)"
      );
    }
    return "rgb(215, 235, 253)";
  }

  formatDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}.${month}.${year}`;
  }
  render() {
    return (
      <div>
        <li className="link">
          <h5>
            <a
              onClick={() => {
                this.props.history.push(
                  "/submission/view/" + this.props.submission.id
                );
              }}
            >
              {this.props.submission.title} (
              {this.formatDate(new Date(this.props.submission.created))})
            </a>
          </h5>
          <div className="description">
            {this.props.submission.author}{" "}
            {this.props.submission.email === ""
              ? "(" + this.props.submission.email + ")"
              : ""}
          </div>
          <div className="description">
            {this.props.submission.wrikeProjectId !== undefined ? (
              this.state.wrikeStatus !== undefined ? (
                <a target="_blank" href={this.props.submission.wrikeLink}>
                  <button
                    style={{
                      backgroundColor: this.hexToRgbA(
                        this.state.wrikeStatus.color
                      ),
                    }}
                    className="tag"
                  >
                    {this.state.wrikeStatus.name}
                  </button>
                </a>
              ) : (
                <Loader />
              )
            ) : (
              ""
            )}
          </div>
        </li>
      </div>
    );
  }
}

export default withRouter(Link);
