import React from "react";
import Link from "./link";
import { TagPicker, Pagination, Loader, DateRangePicker, SelectPicker, Toggle } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import Header from "../../header";
import Cookies from "js-cookie";

import {FetchWithToken} from "../../../adal";

class Submit extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          data: "",
        };
    }

    render() {
        return (
            <div className="modal" style={{display: this.props.showModal  ? 'block' : 'none' }}>
                <div className="modal-content">
                    <h3>Submit JSON</h3>
                    <br/>
                    <textarea style={{height: "300px"}} value={this.state.data} type="text" onChange={(event) => {
                        this.setState({data: event.target.value})
                    }}></textarea>
                    <br/>
                    <div>
                    <button onClick={() => {
                      FetchWithToken("wrike/submit", "POST", JSON.parse(this.state.data))
                      .then((res) => res.json())
                      .then(
                        (response) => {
                          let projectLink = response.data.wrikeLink;
                          if (projectLink !== "")
                            window.open(projectLink, "_blank");
                        }
                      )
                      this.props.onClose();
                    }} style={{marginBottom: "5px", backgroundColor: "#4587FF", width: "50%"}} type="submit">Wrike</button>
                    <button onClick={() => {
                      FetchWithToken("form/submit", "POST", JSON.parse(this.state.data)).then((res) => {
                        this.props.onClose();
                      })
                    }} style={{marginBottom: "5px", backgroundColor: "#4587FF", width: "50%"}} type="submit">Local</button>
                    </div>
                    
                    <button className="cancel" type="submit" onClick={(event) => {this.props.onClose()}}>Cancel</button>
                </div>
            </div>
        )
      }
}

export class SubmissionExplorer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            showModal: false,
            page: 0,
            filterValue: [],
            filterStatus: "",
            dateRange: [],
        };
        this.handlePageSelect = this.handlePageSelect.bind(this);
        this.search = this.search.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.filterUsers = this.filterUsers.bind(this);
        this.filterStatus = this.filterStatus.bind(this);
        this.onClose = this.onClose.bind(this);
    }
  componentDidMount() {
    this.search({users: this.state.filterValue, dateRange: this.state.range, offset: this.state.page*10, limit: 10});
    this.getUsers()
  }

  getUsers() {
    FetchWithToken("user", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var users = [];
          response.data.map((user) => users.push({label: user.name, value: user.email}))
          this.setState({
            users: users,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }
  filterUsers(filterValue) {
    if (filterValue === null) {
      filterValue = [];
      this.search({users: filterValue, dateRange: this.state.range, offset: this.state.page*10, limit: 10});
    } else {
      this.search({users: filterValue, dateRange: this.state.range, offset: 0, limit: 0});
    }
    this.setState({ filterValue });
  }
  filterStatus(filterStatus) {
    if (filterStatus === null) {
      filterStatus = "";
      this.search({users: this.state.filterValue, dateRange: this.state.range, offset: this.state.page*10, limit: 10, status: filterStatus});
    } else {
      this.search({status: filterStatus, users: this.state.filterValue, dateRange: this.state.range, offset: 0, limit: 0});
    }
    this.setState({ filterStatus });
  }

  search(query) {
    this.setState({ready: false});
    FetchWithToken("submission", "POST", query)
    .then((res) => res.json())
    .then(
      (response) => {
        var page = this.state.page;
        var lastPage = Math.ceil(response.data.count/10)-1;
        if (page > lastPage) {
          this.setState({page: lastPage}, this.search({users: this.state.filterValue, dateRange: this.state.range, offset: lastPage*10, limit: 10}));
        }
        else {
          var statuses = [];
          if (response.data.statuses !== null) {
            response.data.statuses.map((status) => statuses.push({label: status.name, value: status.name, color: status.color}));
          }
          this.setState({
            submissions: response.data.submissions,
            statuses: statuses,
            totalCount: response.data.count,
            ready: true
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  onClose() {
    this.setState({showModal: !this.state.showModal})
  }

  handlePageSelect(eventKey) {
    this.setState({ page: eventKey-1 });
    this.search({users: this.state.filterValue, dateRange: this.state.range, offset: (eventKey-1)*10, limit: 10});
  }

  render() {
    return (
      <div>
      <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Submissions", link: "/submissions"}]} back={this.props.history.goBack} hideControls={true} ></Header>
      <div style={{paddingBottom: "6em"}} className="container links-container">
      <Submit onClose={this.onClose} showModal={this.state.showModal}></Submit>
      <div><ul>
      <div className="searchFields" style={{marginBottom: "2em"}}>
      <TagPicker creatable placeholder="Search by Users..." value={this.state.filterValue} onChange={this.filterUsers} style={{width: "49%", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px", marginBottom:"1em"}}  data={this.state.users} menuStyle={{width: 300}} />
      <SelectPicker cleanable renderMenuItem={(label, item) => {
      return (
        <div style={{color: item.color}}>
          <span>{label}</span>
        </div>
      );
    }} placeholder="Search by Wrike Status..." value={this.state.filterStatus} onChange={this.filterStatus} style={{width: "49%", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px", marginBottom:"1em", float: "right"}} data={this.state.statuses} menuStyle={{width: 300}} />
      <DateRangePicker isoWeek={true} disabledDate={DateRangePicker.afterToday(true)} style={{width: "100%", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px", float: "right"}}
      value={this.state.dateRange}
      onChange={value => {
        if (value.length === 0) {
          this.setState({dateRange: [], range: []});
          this.search({users: this.state.filterValue, dateRange: [], offset: 0, limit: 10});
          return
        }
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var range = [(new Date(value[0] - tzoffset)).toISOString(), (new Date(value[1] - tzoffset)).toISOString()]
        this.setState({ dateRange: value, range });
        this.search({users: this.state.filterValue, dateRange: range, offset: 0, limit: 0});
      }}
      />
      </div>
      <br/>
      <br/>
        <Toggle style={{float: "right"}} size="lg" checkedChildren="Table" unCheckedChildren="Default"
        onChange={(event) => {
            Cookies.set("table", "true");
            setTimeout(function(){ window.location.reload(); }, 1000);
        }}
         ></Toggle>
         <br/>
      <br/>
      {this.state.ready === false ? <div style={{textAlign: "center"}}><Loader size="md" /><br/><br/><br/></div> : this.state.submissions !== null ? this.state.submissions.map((s) => (
          <Link key={s.id}  submission={s} />
          )) : "Nothing found!"}
        </ul></div>
        <br/>
        <div style={{textAlign: "center"}}>
          {/* FIXME */}
          {/* <Pagination boundaryLinks ellipsis maxButtons={10} prev next onSelect={this.handlePageSelect} pages={Math.ceil(this.state.totalCount/10)} activePage={this.state.page+1} /> */}
          {this.state.filterValue.length > 0 || this.state.dateRange.length === 2 || this.state.filterStatus !== "" ? "" : <Pagination boundaryLinks ellipsis maxButtons={10} prev next onSelect={this.handlePageSelect} pages={Math.ceil(this.state.totalCount/10)} activePage={this.state.page+1} />}
          {/* {this.state.filterValue ? this.state.filterValue.length > 0 ? "" : this.state.range ? this.state.range.length > 0 ? this.state.filterStatus !== "" ?  "" : <Pagination boundaryLinks ellipsis maxButtons={10} prev next onSelect={this.handlePageSelect} pages={Math.ceil(this.state.totalCount/10)} activePage={this.state.page+1} /> : <Pagination boundaryLinks ellipsis maxButtons={10} prev next onSelect={this.handlePageSelect} pages={Math.ceil(this.state.totalCount/10)} activePage={this.state.page+1} /> : "" : ""} */}
        </div>
        <br/>
        <br/>
        <button className="save" type="submit" onClick={() => {this.onClose()}}>Submit</button>

      </div>
      </div>
    );
  }
}
export default SubmissionExplorer;
