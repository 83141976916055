import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { Router } from "react-router-dom";
import Cookies from "js-cookie";

import { Alert, Modal, Button } from "rsuite";

import NotFound from "./404";
import Info from "./admin/info";

import AdminExplorer from "./admin/explorer";
import AdminFormExplorer from "./admin/forms/explorer";
import AdminFormViewer from "./admin/forms/viewer";
import AdminFormEditor from "./admin/forms/editor";
import AdminDropdownExplorer from "./admin/dropdowns/explorer";
import AdminDropdownEditor from "./admin/dropdowns/editor";
import AdminTemplateExplorer from "./admin/templates/explorer";
import AdminTemplateEditor from "./admin/templates/editor";
import AdminFolderExplorer from "./admin/folders/explorer";
import AdminFolderViewer from "./admin/folders/viewer";
import AdminFolderEditor from "./admin/folders/editor";
import AdminSubmissionExplorer from "./admin/submissions/explorer";
import AdminSubmissionTableExplorer from "./admin/submissions/table-explorer";
import AdminSubmissionViewer from "./admin/submissions/viewer";
import AdminUserExplorer from "./admin/users/explorer";
import AdminUserEditor from "./admin/users/editor";

import UserFormExplorer from "./user/forms/explorer";
import UserFormViewer from "./user/forms/viewer";
import UserSubmissionExplorer from "./user/submissions/explorer";
import UserSubmissionTableExplorer from "./user/submissions/table-explorer";

import { createBrowserHistory } from "history";
import "../assets/styles/form.css";
import { authContext, FetchWithToken } from "../adal";

const history = createBrowserHistory();

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: ["user"],
    };
  }

  componentDidMount() {
    setInterval(function () {
      Alert.warning(
        "Please save your progress and refresh the page. Authorization token will expire soon!",
        0
      );
    }, 1200000);
    var cookie = Cookies.get("roles");
    if (cookie === undefined) {
      FetchWithToken("user/" + authContext._user.profile.email, "GET")
        .then((res) => {
          return res.json();
        })
        .then(
          (response) => {
            var json = response.data;
            Cookies.set("roles", JSON.stringify(json.roles));
            this.setState({
              roles: json.roles,
            });
          },
          (error) => {
            console.error("error: ", error);
          }
        )
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({ roles: JSON.parse(Cookies.get("roles")) });
    }
  }
  render() {
    var admin = false;
    for (const role of this.state.roles) {
      if (role === "admin") {
        admin = true;
      }
    }
    if (admin) {
      return (
        <Router history={history}>
          <div>
            <Switch>
              <Redirect from="/" to="/explorer" exact />
              <Route exact path="/explorer" component={AdminExplorer} />
              <Route exact path="/forms" component={AdminFormExplorer} />
              <Route path="/form/view/:id" component={AdminFormViewer} />
              <Route path="/form/edit/:id" component={AdminFormEditor} />
              <Route path="/form/create" component={AdminFormEditor} />
              <Route
                exact
                path="/dropdowns"
                component={AdminDropdownExplorer}
              />
              <Route path="/dropdown/create" component={AdminDropdownEditor} />
              <Route
                path="/dropdown/edit/:id"
                component={AdminDropdownEditor}
              />
              <Route
                exact
                path="/templates"
                component={AdminTemplateExplorer}
              />
              <Route path="/template/create" component={AdminTemplateEditor} />
              <Route
                path="/template/edit/:id"
                component={AdminTemplateEditor}
              />
              <Route exact path="/folders" component={AdminFolderExplorer} />
              <Route path="/folder/create" component={AdminFolderEditor} />
              <Route path="/folder/view/:id" component={AdminFolderViewer} />
              <Route path="/folder/edit/:id" component={AdminFolderEditor} />
              <Route
                exact
                path="/submissions"
                component={
                  Cookies.get("table") === "false"
                    ? AdminSubmissionExplorer
                    : AdminSubmissionTableExplorer
                }
              />
              <Route
                path="/submission/view/:id"
                component={AdminSubmissionViewer}
              />
              <Route exact path="/users" component={AdminUserExplorer} />
              <Route path="/user/create" component={AdminUserEditor} />
              <Route path="/user/edit/:email" component={AdminUserEditor} />
              <Route exact path="/info" component={Info} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      );
    } else {
      return (
        <Router history={history}>
          <div>
            <Switch>
              <Redirect from="/" to="/explorer" exact />
              <Route exact path="/explorer" component={UserFormExplorer} />
              <Route path="/form/view/:id" component={UserFormViewer} />
              <Route
                exact
                path="/submissions"
                component={
                  Cookies.get("table") === "false"
                    ? UserSubmissionExplorer
                    : UserSubmissionTableExplorer
                }
              />
              <Route
                path="/submission/view/:id"
                component={AdminSubmissionViewer}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      );
    }
  }
}
