import React from "react";
import Link from "./link";
import Header from "../../header";
import {FetchWithToken} from "../../../adal";


export class FolderExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentDidMount() {
    FetchWithToken("folder", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          this.setState({
            folders: response.data,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  render() {
    if (this.state.folders === undefined) {
      return (
        <div className="container links-container">
            <h2>Loading folders...</h2>
          </div>
      );
    } else {
      return (
        <div>
        <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Folders", link: "/folders"}]} back={this.props.history.goBack} hideControls={true} ></Header>
        <div style={{paddingBottom: "6em"}} className="container links-container">
          <div><h2>Available Folders</h2> <ul>
          {this.state.folders !== null ? this.state.folders.map((f) => (
              // do not show folders with parent field
              (f.parent === "" || f.parent === undefined ? <Link instanceName="folder" icon="fa fa-folder" key={f.id}  instance={f} /> : "")
            )) : "Nothing found!"}
          </ul></div>
          <button onClick={() => window.location.href = "/folder/create"} className="save" style={{marginTop: "2em"}} type="submit" >Create New</button>
        </div>
        </div>
      );
    }
  }
}
export default FolderExplorer;
