import React from "react";
import { FetchWithToken } from "../../../adal";
import Link from "./link";
import {
  TagPicker,
  TreePicker,
  Icon,
  Pagination,
  Loader,
  Message,
} from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import Highlighter from "react-highlight-words";
import Header from "../../header";

export class FormExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      query: "",
      tags: [],
      ready: false,
      tagValue: [],
      value: null,
      loadingValues: [],
      page: 0,
    };
    this.addTag = this.addTag.bind(this);
    this.search = this.search.bind(this);
    this.tagChange = this.tagChange.bind(this);
    this.highlight = this.highlight.bind(this);
    this.parseFolders = this.parseFolders.bind(this);
    this.getFolderContent = this.getFolderContent.bind(this);
    this.handleOnExpand = this.handleOnExpand.bind(this);
    this.renderTreeIcon = this.renderTreeIcon.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
  }

  componentDidMount() {
    FetchWithToken("tags", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var tags = [];
          response.data.map((element) =>
            tags.push({ label: element, value: element })
          );
          this.setState({
            tags: tags,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    this.parseFolders();
    this.search({
      tags: this.state.tagValue,
      query: this.state.query,
      offset: this.state.page * 10,
      limit: 10,
    });
  }

  parseFolders() {
    FetchWithToken("folder", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var json = response.data;
          var folders = [];
          json.forEach((folder) => {
            if (folder.parent === undefined || folder.parent === "") {
              folders.push({
                id: folder.id,
                label: folder.title,
                value: folder.tags,
                icon: "fa fa-folder",
                children: [],
              });
            }
          });
          this.setState({ folders: JSON.parse(JSON.stringify(folders)) });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  highlight = (value) => {
    if (this.state.query) {
      return (
        <Highlighter
          searchWords={[this.state.query]}
          textToHighlight={value}
          autoEscape={true}
          highlightStyle={{
            backgroundColor: "rgba(255, 99, 71, 0.7)",
            borderRadius: "4px",
          }}
        />
      );
    } else {
      return value;
    }
  };
  search(query) {
    FetchWithToken("form/search", "POST", query)
      .then((res) => res.json())
      .then(
        (response) => {
          var page = this.state.page;
          var lastPage = Math.ceil(response.data.count / 10) - 1;
          if (page > lastPage) {
            this.setState(
              { page: lastPage },
              this.search({
                tags: this.state.tagValue,
                query: this.state.query,
                offset: lastPage * 10,
                limit: 10,
              })
            );
          } else {
            this.setState({
              forms: response.data.forms,
              totalCount: response.data.count,
              ready: true,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  addTag(tag) {
    var tags = this.state.tagValue;
    if (tags === null) {
      tags = [tag];
      this.setState({ tagValue: tags });
      this.search({
        tags: tags,
        query: this.state.query,
        offset: this.state.page * 10,
        limit: 10,
      });
    }
    if (!tags.includes(tag)) {
      tags.push(tag);
      this.setState({ tagValue: tags });
      this.search({
        tags: tags,
        query: this.state.query,
        offset: this.state.page * 10,
        limit: 10,
      });
    }
  }
  tagChange(tagValue) {
    this.setState({ tagValue });
    this.search({
      tags: tagValue,
      query: this.state.query,
      offset: this.state.page * 10,
      limit: 10,
    });
  }
  handlePageSelect(eventKey) {
    this.setState({ page: eventKey - 1 });
    this.search({
      tags: this.state.tagValue,
      query: this.state.query,
      offset: (eventKey - 1) * 10,
      limit: 10,
    });
  }

  getFolderContent(activeNode) {
    return FetchWithToken(`folder/${activeNode.id}/view`, "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var json = response.data;
          var children = [];
          if (json !== null) {
            json.forEach((el) => {
              if (el.type === "form") {
                children.push({
                  id: el.id,
                  type: el.type,
                  label: el.title,
                  value: el.id,
                  icon: "fa fa-file-text-o",
                });
              } else {
                children.push({
                  id: el.id,
                  type: el.type,
                  label: el.title,
                  value: el.tags,
                  icon: "fa fa-folder",
                  children: [],
                });
              }
            });
          }
          return { activeNode, children: children };
        },
        (error) => {
          console.error(error);
        }
      );
  }

  handleOnExpand(expandItemValues, activeNode, concat) {
    const { folders, loadingValues } = this.state;
    if (activeNode.children.length === 0) {
      if (!loadingValues.includes(activeNode.value)) {
        this.setState({
          loadingValues: [...loadingValues, activeNode.value],
        });
      }
      this.getFolderContent(activeNode).then((response) => {
        const { activeNode: node, children } = response;
        this.setState((prevState) => {
          return {
            folders: concat(folders, children),
            loadingValues: prevState.loadingValues.filter(
              (value) => value !== node.value
            ),
          };
        });
      });
    }
  }

  renderTreeIcon(node, expandIcon) {
    const { loadingValues } = this.state;
    if (loadingValues.includes(node.value)) {
      return <Icon style={{ verticalAlign: "middle" }} icon="spinner" spin />;
    }
    return null;
  }

  render() {
    return (
      <div>
        <Header
          breadcrumbs={[{ title: "Forms", link: "/" }]}
          back={this.props.history.goBack}
        ></Header>
        <div
          style={{ paddingBottom: "6em" }}
          className="container links-container"
        >
          <div>
            <ul>
              <div style={{ marginBottom: "2em" }}>
                <div className="searchFields">
                  <TagPicker
                    creatable
                    placeholder="Search by Tags..."
                    value={this.state.tagValue}
                    onChange={this.tagChange}
                    style={{
                      width: "49%",
                      borderRadius: "2px",
                      outline: "none",
                      lineHeight: "24px",
                      minHeight: "40px",
                    }}
                    data={this.state.tags}
                    menuStyle={{ width: 300 }}
                  />
                  <input
                    placeholder="Search by Name..."
                    value={this.state.query}
                    style={{
                      width: "48%",
                      borderRadius: "2px",
                      outline: "none",
                      lineHeight: "24px",
                      height: "40px",
                      float: "right",
                    }}
                    type="text"
                    onChange={(event) => {
                      this.setState({ query: event.target.value });
                      this.search({
                        tags: this.state.tagValue,
                        query: event.target.value,
                      });
                    }}
                  ></input>
                  <TreePicker
                    cleanable={true}
                    renderTreeIcon={this.renderTreeIcon}
                    renderTreeNode={(nodeData) => {
                      return (
                        <span>
                          <i className={nodeData.icon} /> {nodeData.label}
                        </span>
                      );
                    }}
                    placeholder="Browse Folders"
                    onExpand={this.handleOnExpand}
                    onChange={(value) =>
                      typeof value === "string"
                        ? (window.location.href = `/form/view/${value}`)
                        : ""
                    }
                    value={this.state.value}
                    data={this.state.folders || []}
                    style={{ marginTop: "1em", width: "100%" }}
                  />
                </div>
                <br />
                <Message
                  type="info"
                  // closable
                  onClose={() => {
                    console.log("close");
                  }}
                  description={
                    <p>
                      Welcome to the Group Production website of the ALSO Group!
                      <br /> Here you will find all marketing requests that can
                      be handled by the Group Production Team. To give you an
                      overview of the individual service level agreements, you
                      will find the individual lead times listed here.
                      <br />
                      <b>
                        A start of the processing can therefore take up to 2-4
                        days
                      </b>
                      , depending on the type of request. Please plan this lead
                      time and the actual processing time accordingly in your
                      marketing planning.
                      <br />
                      <br />
                      We would also like to remind you to use the feedback field
                      after processing
                    </p>
                  }
                />
                <br />
              </div>
              {this.state.ready === false ? (
                <div style={{ textAlign: "center" }}>
                  <Loader size="md" />
                  <br />
                  <br />
                  <br />
                </div>
              ) : this.state.forms !== null ? (
                this.state.forms.map((f) => (
                  <Link
                    highlight={this.highlight}
                    addTag={this.addTag}
                    key={f.id}
                    form={f}
                  />
                ))
              ) : (
                "Nothing found!"
              )}
            </ul>
          </div>
          <div style={{ textAlign: "center" }}>
            <Pagination
              prev
              next
              onSelect={this.handlePageSelect}
              pages={Math.ceil(this.state.totalCount / 10)}
              activePage={this.state.page + 1}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default FormExplorer;
