import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import { runWithAdal } from "react-adal";
import { authContext } from "./adal";


const rootElement = document.getElementById("root");
runWithAdal(
  authContext,
  () => {
    ReactDOM.render(<App />, rootElement);
    serviceWorker.unregister();
  },
  process.env.AUTH === "false"
);
