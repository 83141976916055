import React from "react";
import Link from "./link";
import Header from "../../header";
import { FetchWithToken } from "../../../adal";

export class DropdownExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    FetchWithToken("dropdown", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          this.setState({
            dropdowns: response.data,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  render() {
    if (this.state.dropdowns === undefined) {
      return (
        <div className="container links-container">
          <h2>Loading dropdowns...</h2>
        </div>
      );
    } else {
      return (
        <div>
          <Header
            breadcrumbs={[
              { title: "Home", link: "/" },
              { title: "Dropdowns", link: "/dropdowns" },
            ]}
            back={this.props.history.goBack}
            hideControls={true}
          ></Header>
          <div
            style={{ paddingBottom: "6em" }}
            className="container links-container"
          >
            <div>
              <h2>Available Dropdowns</h2>

              {this.state.dropdowns !== null
                ? this.state.dropdowns.map((d) => (
                    <Link key={d.id} dropdown={d} />
                  ))
                : "Nothing found!"}
              <button
                onClick={() => (window.location.href = "/dropdown/create")}
                className="save"
                style={{ marginTop: "2em" }}
                type="submit"
              >
                Create New
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default DropdownExplorer;
