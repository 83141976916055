import React from "react";
import Header from "../../header";
import Delete from "../../deleteModal";
import 'react-toastify/dist/ReactToastify.css';
import {FetchWithToken} from "../../../adal";
import { SelectPicker, TagPicker, Alert } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

export class dropdownEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showModal: false,
      dropdown: {},
      dropdowns: [],
      parentRecords: [],
      create: false,
      newKey: {}
    };
    this.generateValue = this.generateValue.bind(this);
    this.SubmitDropdown = this.SubmitDropdown.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    FetchWithToken("dropdown", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var dropdowns = [];
          dropdowns.unshift({key: "", label: "None", value: ""})
          if (response.data !== null) {
            response.data.forEach(element => {
              dropdowns.push({key: element.id, label: element.title, value: element.id, records: element.records})
            });
          }
          this.setState({
            dropdowns: dropdowns,
          });
          if (this.props.match.params.id !== undefined) {
            FetchWithToken("dropdown/" + this.props.match.params.id, "GET")
              .then((res) => res.json())
              .then(
                (response) => {
                  var json = response.data;
                  var parentRecords = [];
                  if (json.parentDropdown !== "" && json.parentDropdown !== undefined) {
                    parentRecords.unshift({key: "", label: "Always Visible", value: ""})
                    dropdowns.forEach(d=> {
                      if (d.key === json.parentDropdown) {
                        
                        d.records.forEach(record => {
                          parentRecords.push({key: record.value, label: record.label, value: record.value})
                        });
                      }
                    })
                  }
                  this.setState({
                    parentRecords: parentRecords,
                    dropdown: JSON.parse(JSON.stringify(json)),
                    ready: true
                  });
                },
                (error) => {
                  console.error(error);
                }
              ).catch((error) => {
                console.error('Error:', error);
              });
            } else {
              this.setState({
                  create: true,
                  dropdown: {records: []},
                  ready: true
                });
            }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  generateValue(label) {
    var first = true
    return label.replace(/\w+/g, function(txt) {
        if (first) {
          first = false;
          return txt;
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }).replace(/\s/g, '').replace(/[^\w\s]/gi, '');
  }

  SubmitDropdown() {
    FetchWithToken("dropdown", "POST",  this.state.dropdown).then((res) => res.json()).then(
      (response) => {
      if (this.state.create) {
      window.location.href = window.location.origin+"/dropdown/edit/"+response.id
      }
      Alert.success('Dropdown has been successfully saved')
    }).catch((error) => {
      console.error(error)
      Alert.error('Failed to save dropdown')
    })
  }
  onClose() {
    this.setState({showModal: !this.state.showModal})
  }


  render() {
      
    if (!this.state.ready) {
      return (<div className="container" >Loading...</div>)
    } else {
      return (
        <div>
          <Delete redirect="/dropdowns" onClose={this.onClose} showModal={this.state.showModal} instanceName="dropdown" instance={this.state.dropdown}></Delete>
          <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Dropdowns", link: "/dropdowns"}, {title: this.state.dropdown.title || "New", link: ""}]} hideControls={this.state.create} back={this.props.history.goBack} onClose={this.onClose} link={"/dropdown/"} editIcon={"fa fa-print"} id={this.state.dropdown.id}></Header>
      <div className="container" > 
        <label className="col-form-label field-required">Title</label>
        <input value={this.state.dropdown.title || ''} type="text" onChange={(event) => {
            var source = this.state.dropdown;
            source.title = event.target.value;
            this.setState({dropdown: source})
        }}></input>
        <label className="col-form-label field-required">Description (HTML Elements)</label>
        <textarea value={this.state.dropdown.description || ''} type="text" onChange={(event) => {
            var source = this.state.dropdown;
            source.description = event.target.value;
            this.setState({dropdown: source})
        }}></textarea>
        <label className="col-form-label field-required">Parent Dropdown</label>
        <SelectPicker defaultValue="" onChange={(value) => {
            if (value === "") {
              var source = this.state.dropdown;
              source.parentDropdown = "";
              source.records.map((record, index) => {
                source.records[index].parentValues = [];
              });
              this.setState({dropdown: source, parentRecords: []})  
            } else {
              var source = this.state.dropdown;
              var parentRecords = [];
              parentRecords.unshift({key: "", label: "Always Visible", value: ""})
              this.state.dropdowns.forEach(d => {
                if (d.key === value) {
                  d.records.forEach(record => {
                    parentRecords.push({key: record.value, label: record.label, value: record.value})
                  });
                }
              })
              source.parentDropdown = value;
              this.setState({dropdown: source, parentRecords: parentRecords})  
            }
        }} value={this.state.dropdown.parentDropdown} data={this.state.dropdowns} block />
        <div>
          <div>
            <hr/>
            <table className="dropdown-values">
            <thead>
                <tr><th>Parent Values</th><th>Label</th><th>Value</th><th></th></tr>
            </thead>
            <tbody>
              {this.state.dropdown.records.map((record, index) => {
                return (
                  <tr key={index}><th>
                    <TagPicker onChange={(value, event) => {var source = this.state.dropdown; source.records[index].parentValues = value; this.setState({dropdown: source});}} value={this.state.dropdown.records[index].parentValues}  style={{textAlign: "left", width: "100%", minWidth: "200px", maxWidth: "300px", borderRadius: "2px", outline: "none", lineHeight: "24px", minHeight: "40px"}}  data={this.state.parentRecords} defaultValue="" block />

                    </th><th><input onChange={(event) => {var source = this.state.dropdown; source.records[index].label = event.target.value;source.records[index].value = this.generateValue(event.target.value); this.setState({dropdown: source})}} value={record.label} type="text"></input></th><th><input type="text" onChange={(event) => {var source = this.state.dropdown; source.records[index].value = event.target.value; this.setState({dropdown: source})}} value={record.value}></input></th><th><a onClick={(event) => {var source = this.state.dropdown;delete source.records[index];this.setState({dropdown: source})}}   className="button"><i className="fa fa-trash" aria-hidden="true"></i></a></th></tr>
                )
              })}
              <tr><th></th><th></th><th></th><th><a onClick={(_) => {var source = this.state.dropdown;source.records.push({});this.setState({dropdown: source})}} className="button"><i className="fa fa-plus" aria-hidden="true"></i></a></th></tr>
            </tbody>
        </table>
        <br/>
          </div>
        </div>
        <br/>
        <button className="save" type="submit" onClick={this.SubmitDropdown}>Save</button>
      </div>
      </div>
    );
    }
  }
}
export default dropdownEditor;
