import React from "react";
import { Form as Formio, Components } from "react-formio";
import Header from "../../header";
import Delete from "../../deleteModal";
import { FetchWithToken, getToken } from "../../../adal";
import components from "../../formio";
import dompurify from "dompurify";
import { Alert } from "rsuite";
import Choices from "choices.js";
import { Form, FormGroup, Button, FormText, Input, Label } from "reactstrap";

import parse from "html-react-parser";

Components.setComponents(components);

export class FormViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.SubmitForm = this.SubmitForm.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    FetchWithToken("form/" + this.props.match.params.id, "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          this.setState({
            form: response.data,
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  formatDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let hour = date.getHours().toString().padStart(2, "0");
    let minute = date.getMinutes().toString().padStart(2, "0");
    return `${day}.${month}.${year} ${hour}:${minute}`;
  }
  onClose() {
    this.setState({ showModal: !this.state.showModal });
  }

  SubmitForm(submission) {
    submission.Form = this.state.form.id;
    submission.Title = this.state.form.title;
    if (
      this.state.form.blueprint !== undefined ||
      this.state.form.task !== undefined
    ) {
      // wrike/submit triggers form/submit
      FetchWithToken("wrike/submit", "POST", submission)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Failed to submit form");
          }
        })
        .then((response) => {
          Alert.success("Form has been successfully saved");
          Alert.info("Wrike Project is creating. Please wait....", 5000);
          let projectLink = response.data.wrikeLink;
          if (projectLink !== "") window.open(projectLink, "_blank");
        })
        .catch(function (error) {
          Alert.error("Something went wrong. Failed to submit form", 10000);
          console.log(error);
          throw new Error("Server responded with non 200 status code");
        });
    } else {
      FetchWithToken("form/submit", "POST", submission)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to submit form");
          }
        })
        .catch(function (error) {
          Alert.error("Something went wrong. Failed to submit form", 10000);
          console.log(error);
          throw new Error("Server responded with non 200 status code");
        });
    }

    if (this.state.form.submit !== undefined) {
      fetch(this.state.form.submit, {
        method: "POST",
        headers: {
          Authorization: `${getToken()}`,
          Accept: "application/json",
        },
        body: JSON.stringify(submission),
      }).catch(function (error) {
        Alert.error("Something went wrong. Failed to submit form", 10000);
        console.log(error);
        throw new Error("Server responded with non 200 status code");
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    if (this.state.form === undefined) {
      return (
        <div className="container">
          <h2>Loading...</h2>
        </div>
      );
    } else {
      return (
        <div>
          <Header
            breadcrumbs={[
              { title: "Home", link: "/" },
              { title: "Forms", link: "/forms" },
              { title: this.state.form.title || "New", link: "" },
            ]}
            back={this.props.history.goBack}
            onClose={this.onClose}
            link={"/form/edit/"}
            editIcon={"fa fa-pencil"}
            id={this.state.form.id}
          ></Header>
          <Delete
            onClose={this.onClose}
            showModal={this.state.showModal}
            instanceName="form"
            instance={this.state.form}
          ></Delete>
          <div className="container">
            <h3 className="header">{this.state.form.title}</h3>
            <p className="form-description">
              Updated: {this.formatDate(new Date(this.state.form.created))}
              <br />
              Author:{" "}
              {this.state.form.author !== undefined
                ? this.state.form.author
                : "Unknown"}
              <br />
            </p>
            <hr />
            <div
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(this.state.form["description"]),
              }}
            ></div>
            <hr />
            <br />
            {this.state.form.code !== null &&
            this.state.form.code.length > 0 ? (
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  const data = new FormData(event.target);
                  var object = { data: {} };
                  data.forEach(function (value, key) {
                    object.data[key] = value;
                  });
                  alert(JSON.stringify(object));
                  // this.SubmitForm(object);
                }}
              >
                {/* <div
                  dangerouslySetInnerHTML={{ __html: this.state.form.code }}
                ></div> */}
                {parse(this.state.form.code)}
              </Form>
            ) : (
              <Formio
                form={this.state.form}
                onSubmit={this.SubmitForm}
                onError={console.error}
              />
            )}
          </div>
        </div>
      );
    }
  }
}
export default FormViewer;
