import React from "react";

import ReactDOM from "react-dom";
import jexcel from "jexcel";
import "jexcel/dist/jexcel.css";
import settingsForm from "./excelTable.settingsForm";

import { ReactComponent } from "react-formio";

class Jexcel extends React.Component {
  constructor(props) {
    super(props);
    this.setValue = this.setValue.bind(this);
    this.getValue = this.getValue.bind(this);
    var options = props.options;
    options.onevent = this.setValue;
    options.csvFileName = props.label;
    // options.tableWidth = "600px";
    this.state = {
      value: JSON.stringify(props.value),
      options: options,
      tableId: "table-" + Math.random(),
    };
  }

  componentDidMount() {
    this.el = jexcel(
      document.getElementById(this.state.tableId),
      this.state.options
    );
    if (this.state.options.hideIndex) {
      this.el.hideIndex();
    }
    setTimeout(
      function () {
        this.setValue();
      }.bind(this),
      0
    );
  }
  getValue() {
    var value = [];
    if (this.el) {
      value = this.el.getData();
      if (value.length > 0) {
        var headers = [];
        value[0].forEach((_, index) => {
          headers.push(this.el.getHeader(index));
        });
        value.unshift(headers);
      }
    }
    return JSON.stringify(value);
  }
  setValue() {
    // console.log(this.el !== undefined ? this.el : "");
    this.setState({ value: this.getValue() }, () => {
      this.props.onChange(
        null,
        {
          modified: true,
        },
        this.el.updateTable()
      );
    });
  }

  render() {
    return (
      <div>
        <div id={this.state.tableId}></div>
        {this.state.options.editable ? (
          <input
            type="button"
            value="Add"
            onClick={() => this.el.insertRow()}
          ></input>
        ) : (
          ""
        )}
        {this.state.options.downloadable ? (
          <input
            style={{ float: "right" }}
            type="button"
            value="Download"
            onClick={() => this.el.download()}
          ></input>
        ) : (
          ""
        )}
        <br />
      </div>
    );
  }
}

export default class excelTable extends ReactComponent {
  static schema() {
    return ReactComponent.schema({
      options: {
        hideIndex: true,
        downloadable: true,
        defaultColWidth: 100,
        tableOverflow: true,
        editable: true,
        data: [],
        minDimensions: [1, 1],
      },
      label: "Excel Table",
      key: "excelTable",
      type: "excelTable",
    });
  }

  static builderInfo = {
    title: "Excel Table",
    group: "basic",
    icon: "fa fa-window-maximize",
    weight: 70,
    documentation: "https://bossanova.uk/jexcel/v4",
    schema: excelTable.schema(),
  };

  static editForm = settingsForm;

  attachReact(element) {
    return ReactDOM.render(
      <Jexcel
        label={this.component.label}
        options={this.component.options}
        onChange={this.updateValue}
      />,
      element
    );
    // return ReactDOM.render(<Jexcel options={this.component.options} onChange={this.updateValue} parent={document.getElementById(this.component.tableId)} />, document.getElementById(this.component.tableId))
    // return super.attach(element);
  }
}
