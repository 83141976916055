import React from "react";
import Header from "../../header";
import Delete from "../../deleteModal";
import {FetchWithToken} from "../../../adal";
import { Alert } from 'rsuite';

export class templateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showModal: false,
      template: {},
      create: false
    };
    this.SubmitTemplate = this.SubmitTemplate.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
        FetchWithToken("template/" + this.props.match.params.id, "GET")
          .then((res) => res.json())
          .then(
            (response) => {
              this.setState({
                template: response.data,
                ready: true
              });
            },
            (error) => {
              console.error(error);
            }
          ).catch((error) => {
            console.error('Error:', error);
          });
        } else {
          this.setState({
              create: true,
              template: {},
              ready: true
            });
        }
  }


  SubmitTemplate() {
    FetchWithToken("template", "POST",  this.state.template).then((res) => res.json()).then(
      (response) => {
      if (this.state.create) {
      window.location.href = window.location.origin+"/template/edit/"+response.id
      }
      Alert.success('Template has been successfully saved')
    }).catch((error) => {
      console.error(error)
      Alert.error('Failed to save template')
    })
  }
  onClose() {
    this.setState({showModal: !this.state.showModal})
  }


  render() {
      
    if (!this.state.ready) {
      return (<div className="container">Loading...</div>)
    } else {
      return (
        <div>
          <Delete redirect="/templates" onClose={this.onClose} showModal={this.state.showModal} instanceName="template" instance={this.state.template}></Delete>
          <Header breadcrumbs={[{title: "Home", link: "/"}, {title: "Templates", link: "/templates"}, {title: this.state.template.title || "New", link: ""}]} hideControls={this.state.create} back={this.props.history.goBack} onClose={this.onClose} link={"/template/"} editIcon={"fa fa-print"} id={this.state.template.id}></Header>
      <div className="container" > 
        <label className="col-form-label field-required">Title</label>
        <input value={this.state.template.title || ''} type="text" onChange={(event) => {
            var source = this.state.template;
            source.title = event.target.value;
            this.setState({template: source})
        }}></input>
        <label className="col-form-label field-required">Description (HTML Elements)</label>
        <textarea value={this.state.template.description || ''} type="text" onChange={(event) => {
            var source = this.state.template;
            source.description = event.target.value;
            this.setState({template: source})
        }}></textarea>
        <br/>
        <br/>
        <label className="col-form-label field-required">Schema</label>
        <textarea value={this.state.template.schema || ''} type="text" onChange={(event) => {
            var source = this.state.template;
            source.schema = event.target.value;
            this.setState({template: source})
        }}></textarea>
                <button className="save" type="submit" onClick={this.SubmitTemplate}>Save</button>

        </div>
      </div>
    );
    }
  }
}
export default templateEditor;
