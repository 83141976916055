import React from "react";
import Link from "../link";
import Report from "./report";

export class Explorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    return (
      <div
        style={{ paddingBottom: "1em", width: "40%", minWidth: 800 }}
        className="container links-container"
      >
        <Report
          onClose={this.onClose}
          redirect={window.location.pathname}
          showModal={this.state.showModal}
        ></Report>
        <div>
          <h2>Explorer</h2>{" "}
          <ul>
            <Link
              href="/forms"
              description="Explore all available forms"
              title="Forms"
            />
            <Link
              href="/folders"
              description="Browse folders"
              title="Folders"
            />
            <Link
              href="/submissions"
              description="Validate form submissions"
              title="Submissions"
            />
            <Link
              href="/dropdowns"
              description="Manage default dropdowns"
              title="Dropdowns"
            />
            {/* <Link href="/files" description="Browse uploaded files" title="Files" /> */}
            <Link
              href="/templates"
              description="Manage predefined formio components"
              title="Templates"
            />
            <Link
              href="/users"
              description="Role-based access control configuration"
              title="Users"
            />
          </ul>
        </div>
        <br />
        {/* <a
          style={{ float: "right", cursor: "pointer", fontSize: "2em" }}
          onClick={() => {
            this.onClose();
          }}
        >
          <i className="fa fa-bug"></i>
        </a> */}
        <br />
      </div>
    );
  }
}
export default Explorer;
