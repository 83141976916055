import React from "react";
import {
  TagPicker,
  Pagination,
  DateRangePicker,
  SelectPicker,
  Table,
  IconButton,
  Icon,
  Toggle,
} from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import Header from "../../header";
import { FetchWithToken } from "../../../adal";
import Cookies from "js-cookie";
import Delete from "../../deleteModal";

const { Column, HeaderCell, Cell } = Table;

// document.addEventListener("contextmenu", (event) => {
//   event.preventDefault();
// });

export class SubmissionTableExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showModal: false,
      page: 0,
      filterValue: [],
      filterStatus: "",
      deleteSubmission: {},
      dateRange: [],
    };
    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.search = this.search.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.filterStatus = this.filterStatus.bind(this);
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount() {
    this.search({
      users: this.state.filterValue,
      dateRange: this.state.range,
      offset: this.state.page * 100,
      limit: 100,
    });
    this.getUsers();
  }

  getUsers() {
    FetchWithToken("user", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var users = [];
          response.data.map((user) =>
            users.push({ label: user.name, value: user.email })
          );
          this.setState({
            users: users,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }
  filterUsers(filterValue) {
    if (filterValue === null) {
      filterValue = [];
      this.search({
        users: filterValue,
        dateRange: this.state.range,
        offset: this.state.page * 100,
        limit: 100,
      });
    } else {
      this.search({
        users: filterValue,
        dateRange: this.state.range,
        offset: 0,
        limit: 0,
      });
    }
    this.setState({ filterValue });
  }
  filterStatus(filterStatus) {
    if (filterStatus === null) {
      filterStatus = "";
      this.search({
        users: this.state.filterValue,
        dateRange: this.state.range,
        offset: this.state.page * 100,
        limit: 100,
        status: filterStatus,
      });
    } else {
      this.search({
        status: filterStatus,
        users: this.state.filterValue,
        dateRange: this.state.range,
        offset: 0,
        limit: 0,
      });
    }
    this.setState({ filterStatus });
  }

  search(query) {
    this.setState({ ready: false });
    FetchWithToken("submission", "POST", query)
      .then((res) => res.json())
      .then(
        (response) => {
          var page = this.state.page;
          var lastPage = Math.ceil(response.data.count / 100) - 1;
          if (page > lastPage) {
            this.setState(
              { page: lastPage },
              this.search({
                users: this.state.filterValue,
                dateRange: this.state.range,
                offset: lastPage * 100,
                limit: 100,
              })
            );
          } else {
            var filterStatuses = [];
            if (response.data.filterStatuses !== null) {
              response.data.filterStatuses.map((status) =>
                filterStatuses.push({
                  label: status.name,
                  value: status.name,
                  color: status.color,
                })
              );
            }
            this.setState({
              submissions: response.data.submissions,
              filterStatuses: filterStatuses,
              statuses: response.data.statuses,
              totalCount: response.data.count,
              ready: true,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  onClose() {
    this.setState({ showModal: !this.state.showModal });
  }

  handlePageSelect(eventKey) {
    this.setState({ page: eventKey - 1 });
    this.search({
      users: this.state.filterValue,
      dateRange: this.state.range,
      offset: (eventKey - 1) * 100,
      limit: 100,
    });
  }
  formatDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}.${month}.${year}`;
  }
  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",20%)"
      );
    }
    return "rgb(215, 235, 253)";
  }

  render() {
    return (
      <div>
        <Header
          breadcrumbs={[
            { title: "Home", link: "/" },
            { title: "Submissions", link: "/submissions" },
          ]}
          back={this.props.history.goBack}
          hideControls={true}
        ></Header>
        <div
          style={{ paddingBottom: "6em" }}
          className="container links-container"
        >
          <div>
            <ul>
              <div className="searchFields" style={{ marginBottom: "2em" }}>
                <TagPicker
                  creatable
                  placeholder="Search by Users..."
                  value={this.state.filterValue}
                  onChange={this.filterUsers}
                  style={{
                    width: "49%",
                    borderRadius: "2px",
                    outline: "none",
                    lineHeight: "24px",
                    minHeight: "40px",
                    marginBottom: "1em",
                  }}
                  data={this.state.users}
                  menuStyle={{ width: 300 }}
                />
                <SelectPicker
                  cleanable
                  renderMenuItem={(label, item) => {
                    return (
                      <div style={{ color: item.color }}>
                        <span>{label}</span>
                      </div>
                    );
                  }}
                  placeholder="Search by Wrike Status..."
                  value={this.state.filterStatus}
                  onChange={this.filterStatus}
                  style={{
                    width: "49%",
                    borderRadius: "2px",
                    outline: "none",
                    lineHeight: "24px",
                    minHeight: "40px",
                    marginBottom: "1em",
                    float: "right",
                  }}
                  data={this.state.filterStatuses}
                  menuStyle={{ width: 300 }}
                />
                <DateRangePicker
                  isoWeek={true}
                  disabledDate={DateRangePicker.afterToday(true)}
                  style={{
                    width: "100%",
                    borderRadius: "2px",
                    outline: "none",
                    lineHeight: "24px",
                    minHeight: "40px",
                    float: "right",
                  }}
                  value={this.state.dateRange}
                  onChange={(value) => {
                    if (value.length === 0) {
                      this.setState({ dateRange: [], range: [] });
                      this.search({
                        users: this.state.filterValue,
                        dateRange: [],
                        offset: 0,
                        limit: 100,
                      });
                      return;
                    }
                    var tzoffset = new Date().getTimezoneOffset() * 60000;
                    var range = [
                      new Date(value[0] - tzoffset).toISOString(),
                      new Date(value[1] - tzoffset).toISOString(),
                    ];
                    this.setState({ dateRange: value, range });
                    this.search({
                      users: this.state.filterValue,
                      dateRange: range,
                      offset: 0,
                      limit: 0,
                    });
                  }}
                />
              </div>
              <br />
              <br />
              <Toggle
                style={{ float: "right" }}
                defaultChecked
                size="lg"
                checkedChildren="Table"
                unCheckedChildren="Default"
                onChange={(event) => {
                  Cookies.set("table", "false");
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                }}
              ></Toggle>
              <Delete
                onClose={this.onClose}
                redirect="/submissions"
                showModal={this.state.showModal}
                instanceName="submission"
                instance={this.state.deleteSubmission}
              ></Delete>
              <br />

              <br />

              <Table
                hover
                loading={!this.state.ready}
                autoHeight
                bordered
                //   cellBordered
                data={this.state.submissions}
                onRowClick={(data) => {
                  this.props.history.push("/submission/view/" + data.id);
                }}
              >
                <Column flexGrow={3} fixed="left">
                  <HeaderCell>Status</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      var status;
                      for (let stat of this.state.statuses) {
                        if (stat.project === rowData.wrikeProjectId) {
                          status = stat;
                          break;
                        }
                      }
                      if (status !== undefined) {
                        return (
                          <a target="_blank" href={rowData.wrikeLink}>
                            <button
                              style={{
                                backgroundColor: this.hexToRgbA(status.color),
                              }}
                              className="tag"
                            >
                              {status.name.length > 1 ? status.name : "Unknown"}
                            </button>
                          </a>
                        );
                      }
                      return <button className="tag">Local</button>;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={6}>
                  <HeaderCell>Title</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      return rowData.title;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={3}>
                  <HeaderCell>Author</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      return rowData.author;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={2}>
                  <HeaderCell>Date</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      return this.formatDate(new Date(rowData.created));
                    }}
                  </Cell>
                </Column>
                <Column width={40}>
                  <HeaderCell></HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) => {
                      return (
                        <Icon
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState(
                              {
                                deleteSubmission: this.state.submissions[
                                  rowIndex
                                ],
                              },
                              this.onClose()
                            );
                          }}
                          className="fill-color"
                          icon="trash"
                          size="lg"
                        />
                      );
                    }}
                  </Cell>
                </Column>
              </Table>
            </ul>
          </div>
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            {this.state.filterValue.length > 0 ||
            this.state.dateRange.length === 2 ||
            this.state.filterStatus !== "" ? (
              ""
            ) : (
              <Pagination
                boundaryLinks
                ellipsis
                maxButtons={10}
                prev
                next
                onSelect={this.handlePageSelect}
                pages={Math.ceil(this.state.totalCount / 100)}
                activePage={this.state.page + 1}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default SubmissionTableExplorer;
