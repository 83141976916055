import baseEditForm from 'formiojs/components/_classes/component/Component.form';

export default (...extend) => {
  return baseEditForm([
    {
      key: 'display',
      components: [
        {
          key: 'placeholder',
          ignore: true,
        },
        {
          key: 'labelPosition',
          ignore: true,
        },
        {
          key: 'description',
          ignore: true,
        },
        {
          key: 'tooltip',
          ignore: true,
        },
        {
          type: 'checkbox',
          input: true,
          label: 'Enable Table Editing',
          weight: 1,
          key: 'options.editable',
        },
        {
          type: 'checkbox',
          input: true,
          label: 'Hide row number column',
          weight: 1,
          key: 'options.hideIndex',
        },
        {
          type: 'checkbox',
          input: true,
          label: 'Allow users to download table',
          weight: 1,
          key: 'options.downloadable',
        },
        {
          type: 'number',
          input: true,
          label: 'Default Column Width',
          weight: 2,
          key: 'options.defaultColWidth',
          validate: {
            min: 0,
            customMessage: 'Default column width should be less than 1000px',
            json: '',
            max: 1000,
          },
        },
        {
          type: 'datagrid',
          input: true,
          label: 'Table Columns',
          key: 'options.columns',
          weight: 3,
          reorder: true,
          components: [
            {
              type: 'select',
              defaultValue: 'text',
              input: true,
              label: 'Type',
              key: 'type',
              template: '<span>{{ item.label }}</span>',
              dataSrc: 'values',
              data: {
                values: [
                  { label: 'Text', value: 'text' },
                  { label: 'Dropdown', value: 'dropdown' },
                  { label: 'Calendar', value: 'calendar' },
                  { label: 'Image', value: 'image' },
                  { label: 'Checkbox', value: 'checkbox' },
                  { label: 'Numeric', value: 'numeric' },
                  { label: 'Color', value: 'color' },
                ],
              },
            },
            {
              label: 'Title',
              key: 'title',
              input: true,
              type: 'textfield',
            },
            {
              type: 'number',
              defaultValue: 100,
              input: true,
              label: 'Width',
              key: 'width',
              validate: {
                min: 0,
                customMessage: 'Column width should be less than 1000px',
                json: '',
                max: 1000,
              },
            },
            {
              label: 'Data',
              key: 'source',
              input: true,
              type: 'tags',
              storeas: 'array',
            },
          ],
        },
      ]
    },
    {
      key: 'data',
      
      components: [
        {
          key: 'multiple',
          ignore: true,
        },
        {
          key: 'defaultValue',
          ignore: true,
        },
        {
          key: 'persistent',
          ignore: true,
        },
        
      ],
    },
  ], ...extend);
}