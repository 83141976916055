import React from "react";
import { Components, FormBuilder } from "react-formio";
import Header from "../../header";
import Delete from "../../deleteModal";
import "react-toastify/dist/ReactToastify.css";
import { TagPicker, SelectPicker, Checkbox, Alert, Toggle } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import components from "../../formio";
import { FetchWithToken } from "../../../adal";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

Components.setComponents(components);

export class FormEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      showModal: false,
      form: {},
      source: {},
      blueprints: [],
      roleValue: [],
      folders: [],
      tags: [],
      create: false,
      code: true,
      enableRoles: false,
    };
    this.SubmitForm = this.SubmitForm.bind(this);
    this.CancelEditing = this.CancelEditing.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getBlueprints = this.getBlueprints.bind(this);
    this.getFolders = this.getFolders.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

    this.formUpdated = this.formUpdated.bind(this);
  }
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      FetchWithToken("form/" + this.props.match.params.id, "GET")
        .then((res) => res.json())
        .then(
          (response) => {
            var json = response.data;
            var parsed = JSON.parse(JSON.stringify(json));
            this.setState({
              form: parsed,
              code:
                parsed.code !== null && parsed.code.length > 0 ? true : false,
              source: parsed,
              tagValue: json.tags,
              roleValue: json.roles,
              enableRoles: json.roles.length > 0,
              ready: true,
            });
          },
          (error) => {
            console.error(error);
          }
        )
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({
        create: true,
        form: { display: "form" },
        source: { display: "form" },
        ready: true,
      });
    }
    FetchWithToken("roles", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var roles = [{ label: "admin", value: "admin" }];
          response.data.map((element) => {
            if (element !== "admin") {
              roles.push({ label: element, value: element });
            }
          });
          this.setState({
            roles: roles,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    FetchWithToken("tags", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var tags = [];
          response.data.map((element) =>
            tags.push({ label: element, value: element })
          );
          this.setState({
            tags: tags,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    this.getBlueprints();
    this.getFolders();
  }

  getBlueprints() {
    FetchWithToken("wrike/blueprints", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var data = response.data;
          var blueprints = [];
          blueprints.unshift({ id: "", label: "None", value: "" });
          data.forEach((blueprint) => {
            blueprints.push({
              key: blueprint.id,
              label: blueprint.title,
              value: blueprint.id,
            });
          });
          this.setState({
            blueprints: blueprints,
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getFolders() {
    FetchWithToken("wrike/folders", "GET")
      .then((res) => res.json())
      .then(
        (response) => {
          var data = response.data;
          var folders = [];
          folders.unshift({ id: "", label: "Default", value: "" });
          data.forEach((folder) => {
            folders.push({
              key: folder.id,
              label: folder.title,
              value: folder.id,
            });
          });
          this.setState({
            folders: folders,
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  SubmitForm() {
    this.setState({ submitPressed: true });
    var title = this.state.form.title;
    var description = this.state.form.description;
    if (title === undefined || title.trim().length < 1) {
      Alert.warning("Please provide form title");
      document.getElementById("title").focus();
      return;
    } else if (description === undefined || description.trim().length < 1) {
      Alert.warning("Please provide form description");
      document.getElementById("description").focus();
      return;
    }
    var temp = this.state.form;
    temp.tags = this.state.tagValue !== null ? this.state.tagValue : [];
    if (!this.state.enableRoles) {
      temp.roles = [];
    } else {
      temp.roles = this.state.roleValue !== null ? this.state.roleValue : [];
    }
    FetchWithToken("form", "POST", temp)
      .then((res) => {
        if (res.status === 401) {
          return Promise.reject("Unauthorized");
        }
        return res.json();
      })
      .then((response) => {
        this.setState({
          form: JSON.parse(JSON.stringify(temp)),
          source: JSON.parse(JSON.stringify(temp)),
        });
        if (this.state.create) {
          window.onbeforeunload = undefined;
          window.location.href =
            window.location.origin + "/form/edit/" + response.id;
        }
        Alert.success("Form has been successfully saved");
      })
      .catch((error) => {
        console.log(error);
        Alert.error("Failed to save form");
      });
  }
  CancelEditing() {
    this.setState({ form: this.state.source });
  }
  onClose() {
    this.setState({ showModal: !this.state.showModal });
  }

  handleTagChange(tagValue) {
    this.setState({ tagValue });
  }
  handleRoleChange(roleValue) {
    if (roleValue.indexOf("admin") === -1) {
      roleValue.unshift("admin");
    }
    this.setState({ roleValue });
  }
  formUpdated(schema) {
    if (JSON.stringify(schema) !== JSON.stringify(this.state.source)) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    this.state.form = schema;
  }

  render() {
    if (!this.state.ready) {
      return <div className="container">Loading...</div>;
    } else {
      return (
        <div>
          <Delete
            onClose={this.onClose}
            showModal={this.state.showModal}
            instanceName="form"
            instance={this.state.form}
          ></Delete>
          <Header
            breadcrumbs={[
              { title: "Home", link: "/" },
              { title: "Forms", link: "/forms" },
              { title: this.state.form.title || "New", link: "" },
            ]}
            hideControls={this.state.create}
            back={this.props.history.goBack}
            onClose={this.onClose}
            link={"/form/view/"}
            editIcon={"fa fa-print"}
            id={this.state.form.id}
          ></Header>
          <div className="container">
            <label className="col-form-label field-required">Title</label>
            <input
              id="title"
              className={
                this.state.form.title === undefined ||
                this.state.form.title.trim() === ""
                  ? this.state.submitPressed
                    ? "error-input"
                    : ""
                  : ""
              }
              value={this.state.form.title || ""}
              type="text"
              onChange={(event) => {
                var source = this.state.form;
                source.title = event.target.value;
                this.setState({ form: source });
              }}
            ></input>
            <label className="col-form-label field-required">
              Description (HTML Elements)
            </label>
            <textarea
              className={
                this.state.form.description === undefined ||
                this.state.form.description.trim() === ""
                  ? this.state.submitPressed
                    ? "error-input"
                    : ""
                  : ""
              }
              id="description"
              value={this.state.form.description || ""}
              type="text"
              onChange={(event) => {
                var source = this.state.form;
                source.description = event.target.value;
                this.setState({ form: source });
              }}
            ></textarea>

            <Checkbox
              onChange={() => {
                this.setState({ enableRoles: !this.state.enableRoles });
              }}
              checked={this.state.enableRoles}
            >
              Restrict Form Access
            </Checkbox>
            {this.state.enableRoles ? (
              <div>
                <label className="col-form-label">Roles</label>
                <br />
                <TagPicker
                  value={this.state.roleValue}
                  onChange={this.handleRoleChange}
                  style={{
                    width: "100%",
                    borderRadius: "2px",
                    outline: "none",
                    lineHeight: "24px",
                    minHeight: "40px",
                  }}
                  data={this.state.roles}
                  defaultValue={this.state.roleValue}
                  menuStyle={{ width: 300 }}
                />
                <br />
              </div>
            ) : (
              ""
            )}

            <label className="col-form-label">Tags</label>
            <br />
            <TagPicker
              value={this.state.tagValue}
              onChange={this.handleTagChange}
              style={{
                width: "100%",
                borderRadius: "2px",
                outline: "none",
                lineHeight: "24px",
                minHeight: "40px",
              }}
              creatable
              data={this.state.tags}
              defaultValue={this.state.tagValue}
              menuStyle={{ width: 300 }}
            />
            <br />
            <label className="col-form-label">Wrike Blueprint</label>
            <SelectPicker
              onChange={(value) => {
                var source = this.state.form;
                source.blueprint = value;
                this.setState({ form: source });
              }}
              value={this.state.form.blueprint}
              data={this.state.blueprints}
              block
            />

            <label className="col-form-label">Wrike Task</label>
            <input
              placeholder="legacy ID"
              id="task"
              value={this.state.form.task || ""}
              type="text"
              onChange={(event) => {
                var source = this.state.form;
                source.task = event.target.value;
                this.setState({ form: source });
              }}
            ></input>
            <br />

            <div
              style={{
                display:
                  this.state.form.blueprint !== undefined ||
                  this.state.form.task !== undefined
                    ? "inherit"
                    : "none",
              }}
            >
              <label className="col-form-label">Wrike Folder</label>
              {this.state.form.task ? (
                <input
                  placeholder="legacy ID"
                  id="folder"
                  value={this.state.form.folder || ""}
                  type="text"
                  onChange={(event) => {
                    var source = this.state.form;
                    source.folder = event.target.value;
                    this.setState({ form: source });
                  }}
                ></input>
              ) : (
                <SelectPicker
                  onChange={(value) => {
                    var source = this.state.form;
                    source.folder = value;
                    this.setState({ form: source });
                  }}
                  value={this.state.form.folder}
                  defaultValue=""
                  data={this.state.folders}
                  block
                />
              )}
            </div>

            <label className="col-form-label">Submit URL</label>
            <input
              value={this.state.form.submit || ""}
              type="text"
              onChange={(event) => {
                var source = this.state.form;
                source.submit = event.target.value;
                this.setState({ form: source });
              }}
            ></input>
            <hr />
            <Toggle
              style={{ float: "right" }}
              defaultChecked
              size="lg"
              checkedChildren="Builder"
              unCheckedChildren="Code"
              onChange={(event) => {
                this.setState({ code: !event });
              }}
            ></Toggle>
            <br />
            <br />
            {this.state.code ? (
              <AceEditor
                style={{ width: "100%" }}
                mode="javascript"
                value={this.state.form.code}
                theme="github"
                height={1000}
                onChange={(code) => {
                  var temp = this.state.form;
                  temp.code = code;
                  this.setState({ form: temp });
                }}
              />
            ) : (
              <FormBuilder
                key={this.state.randomKey}
                form={this.state.form}
                options={{
                  builder: {
                    basic: {
                      components: {
                        excelTable: true,
                      },
                    },
                    advanced: {
                      components: {
                        file: true,
                      },
                    },
                    premium: false,
                  },
                }}
                onChange={this.formUpdated}
                onError={console.error}
              />
            )}
            <br />
            <button className="save" type="submit" onClick={this.SubmitForm}>
              Save
            </button>
            <button
              style={{ marginRight: "10px" }}
              className="cancel"
              type="submit"
              onClick={this.CancelEditing}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }
  }
}
export default FormEditor;
