import React from "react";
import { Uploader } from 'rsuite';
import { API_URL, getToken, FetchWithToken } from '../../adal';


class Report extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          uploading: [],
          description: "",
          links: [],
          value: [],
        };
      this.submit = this.submit.bind(this);
      this.handleChange = this.handleChange.bind(this);
    }

    submit() {
      console.log("submit");
    }
    handleChange(value) {
      this.setState({ value });
    }

    render() {
        return (
            <div className="modal" style={{display: this.props.showModal  ? 'block' : 'none' }}>
                <div className="modal-content">
                    <h3>Send Report</h3>
                    <br/>
                    <textarea placeholder="Report Description" value={this.state.description} type="text" onChange={(event) => {
                        this.setState({description: event.target.value})
                    }}></textarea>
                    <br/>
                    {/* we can't use autoUpload because of cors issue when running in development mode */}
                    <Uploader action={`${API_URL}/file`} headers={{"Authorization": `Bearer ${getToken()}`}} onSuccess={(response, file) => {
                      var joined = this.state.links.concat(`http://request.also.com/api/file/${response.id}`);
                      this.setState({ links: joined })
                    }} autoUpload={true} fileList={this.state.value} onChange={this.handleChange} draggable>
                      <div style={{lineHeight: "100px"}}>Upload Screenshots or Screen Recordings</div>
                    </Uploader>
                    <br/>
                    <br/>
                    {/* <div style={{color: "#e03a47"}} className="description">Report submission has been disabled</div> */}
                    <button onClick={() => {
                      FetchWithToken("service/report", "POST", {description: this.state.description, links: this.state.links}).then((res) => {
                        console.log(res.status);
                        this.setState({
                          description: "",
                          links: [],
                          value:[],
                        })
                        this.props.onClose();
                      })
                    }} style={{marginBottom: "5px", backgroundColor: "#4587FF"}} type="submit">Submit</button>
                    <button className="cancel" type="submit" onClick={(event) => {this.props.onClose()}}>Cancel</button>
                </div>
            </div>
        )
      }
}

export default Report;