import React from "react";
import Delete from "./deleteModal";
import { Avatar } from "rsuite";
import { withRouter } from "react-router-dom";

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.formatDate = this.formatDate.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ showModal: !this.state.showModal });
  }

  formatDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}.${month}.${year}`;
  }
  render() {
    return (
      <div>
        <Delete
          onClose={this.onClose}
          showModal={this.state.showModal}
          instanceName="user"
          instance={this.props.user}
        ></Delete>
        <li className="link">
          <a
            className="delete"
            onClick={() => this.setState({ showModal: true })}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
          <a
            className="edit"
            onClick={() => {
              this.props.history.push("/user/edit/" + this.props.user.email);
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </a>
          <h5 style={{ marginBottom: "5px" }}>
            <Avatar style={{ marginRight: "10px" }} circle>
              {this.props.user.name.match(/\b(\w)/g).join("")}
            </Avatar>
            {this.props.user.name} (
            {this.formatDate(new Date(this.props.user.created))})
          </h5>
          <div className="description">
            <b>Name:</b> {this.props.user.name} <br />
            <b>Email:</b> {this.props.user.email} <br />
            <b>Roles:</b>{" "}
            <span style={{ color: "red" }}>
              {this.props.user.roles.join(", ")}
            </span>{" "}
            <br />
          </div>
        </li>
      </div>
    );
  }
}

export default withRouter(Link);
