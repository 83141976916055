import React from "react";
import {FetchWithToken} from "../../../adal";

class Delete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmed: false,
            input: ""
          };
        this.deleteInstance = this.deleteInstance.bind(this);
      }

      deleteInstance() {
        FetchWithToken(this.props.instanceName+"/"+this.props.instance.email, "DELETE").then((response) => {
          if(response.ok){
            window.location.href = (this.props.redirect !== undefined ? this.props.redirect : "/");
          }
        })
      }

    render() {
      if (this.props.instance === undefined) {
        return ("")
      } else {
        return (
            <div className="modal" style={{display: this.props.showModal  ? 'block' : 'none' }}>
                <div className="modal-content">
                    <h3>Are you absolutely sure?</h3>
                    <br/>
                    <p>This action <b>cannot</b> be undone.</p>
                    <p>This will permanently delete the '{this.props.instance.title}' {this.props.instanceName} and remove all associations</p>
                    <br/>
        <label className="col-form-label">Please type '<b>{this.props.instance.email}</b>' to confirm.</label>
                    <input value={this.state.input} onChange={(event) => {this.setState({input: event.target.value})}} type="text"></input>
                    <br/>
                    <button disabled={(this.state.input === this.props.instance.email)? false : true} style={{marginBottom: "5px", backgroundColor: "#CB2431"}} type="submit" onClick={this.deleteInstance} >Delete</button>
                    <button className="cancel" type="submit" onClick={(event) => {this.props.onClose()}}>Cancel</button>
                </div>
            </div>
        )
      }
    }
}

export default Delete;